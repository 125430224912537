import * as appmodel from "./offer-creation.appmodel.json";
import { Module } from "@com.mgmtp.a12/bap-client/lib/core/application";
import { ApplicationModel } from "@com.mgmtp.a12/bap-client/lib/core/model";
import * as React from "react";
import { View } from "@com.mgmtp.a12/bap-client/lib/core/view";
import { loginButtonMiddleware } from "./middlewares/loginButtonMiddleware";
import { premiumButtonMiddleware } from "./middlewares/premiumButtonMiddleware";
import { acceptOfferWithInvoiceButtonMiddleware } from "./middlewares/acceptOfferWithInvoiceButton";
import { OfferCreationContainer } from "./containers/OfferCreationContainer";
import { openContractPageMiddleware } from "./middlewares/openContractPageMiddleware";
import { policyHolderButtonMiddleware } from "./middlewares/policyHolderButtonMiddleware";
import { OfferSentContainer } from "./containers/OfferSentContainer";
import { PageNotFoundContainer } from "./containers/PageNotFoundContainer";
import { PageOfferExpiredContainer } from "./containers/PageOfferExpiredContainer";
import { PageAlreadyPayedContainer } from "./containers/PageAlreadyPayedContainer";
import { createOfferButtonMiddlewareWrapper } from "ggw-customer-portal-common/lib/offer-creation";
import { SaferpayMiddlewares } from "ggw-customer-portal-common/lib/saferpay";
import { OfferNotFoundContainer } from "./containers/OfferNotFoundContainer";
import { PolicyNotCreatedContainer } from "./containers/PolicyNotCreatedContainer";
import { policyHolderOnPaymentPageMiddleware } from "./middlewares/policyHolderOnPaymentPageMittleware";
import { backButtonMiddleware } from "./middlewares/backButtonMiddleware";
import { ConnectorLocator } from "@com.mgmtp.a12/server-connector/lib/connector/ConnectorLocator";
import { ActivityActions } from "@com.mgmtp.a12/bap-client/lib/core/activity";

function viewComponentProvider(componentName: string): React.ComponentType<View> | undefined {
	return Views[componentName];
}

const Views: { [name: string]: React.ComponentType<View> } = {
	OfferCreationContainer,
	OfferSentContainer,
	PageNotFoundContainer,
	PageOfferExpiredContainer,
	PageAlreadyPayedContainer,
	OfferNotFoundContainer,
	PolicyNotCreatedContainer
};

const OfferCreationModule: Module = {
	id: "offer-creation.module",
	model: () => appmodel as ApplicationModel,
	views: () => viewComponentProvider,
	middlewares: () => [
		acceptOfferWithInvoiceButtonMiddleware,
		createOfferButtonMiddlewareWrapper(
			"/insurance/fy-customer-portal/offer",
			"de",
			ConnectorLocator.getInstance().getServerConnector(),
			[],
			[ActivityActions.create({
				activityDescriptor: {
					container: "OfferSentContainer"
				}
			})]
		),
		loginButtonMiddleware,
		premiumButtonMiddleware,
		openContractPageMiddleware,
		policyHolderButtonMiddleware,
		policyHolderOnPaymentPageMiddleware,
		backButtonMiddleware,
		...SaferpayMiddlewares.createMiddlewares({
			alreadyPayed: "PageAlreadyPayedContainer",
			offerNotFound: "OfferNotFoundContainer",
			policyNotCreated: "PolicyNotCreatedContainer"
		}, ConnectorLocator.getInstance().getServerConnector(), "firmenich")
	]
};

export default OfferCreationModule;
