import * as React from "react";

import {
	LayoutGrid,
	ActionContentbox,
	ContentBoxElements
} from "@com.mgmtp.a12/widgets";

import { Localizer } from "@com.mgmtp.a12/bap-client/lib/core/locale";
import { connect } from "react-redux";
import { FormEngineActions } from "@com.mgmtp.a12/bap-client/lib/extensions/bap-form-engine";
import { Activity } from "@com.mgmtp.a12/bap-client/lib/core/activity";
import { Events } from "@com.mgmtp.a12/bap-form-engine/lib/back-end/store";
import { FyHeader } from "../ui-components/FyHeader";
import { FyFooter } from "../ui-components/FyFooter";
import { CustomerPortalSectionConnected as CustomerPortalSection } from "../ui-components/CustomerPortalSection";

const { Row, Column } = LayoutGrid;

interface ContentElements {
	id?: string;
	title?: string;
	subtitle?: string;
	contentPrimaryTitle?: string;
	contentPrimary: JSX.Element;
	contentPrimaryContentbox: boolean;
	contentSecondaryTitle?: string;
	contentSecondary?: JSX.Element;
	layout: {
		contentPrimarySize: LayoutGrid.ColumnSize;
		contentSecondarySize?: LayoutGrid.ColumnSize
	};
	children?: JSX.Element;
}

interface TemplateVerticalProps {
	localizer: Localizer;
	activity: Activity;
}

interface DispatchProps {
	openImprintPage(): void;
}

export class TemplateVerticalComponent extends React.Component<ContentElements & TemplateVerticalProps & DispatchProps> {
	render() {
		return (
			<div className="page-wrapper">
				<FyHeader activity={this.props.activity} />
				<div className="contentWrapper--outer" id={this.props.id}>
					<div className="contentWrapper--inner">
						{this.props.children}
						<LayoutGrid className="layoutGrid--content page__section">
							<Row>
								<Column size={{ sm: 4, md: 6, lg: 12 }}>
									<CustomerPortalSection activity={this.props.activity} />
								</Column>
							</Row>
							<Row>
								<Column size={this.props.layout.contentPrimarySize}>
									{
										this.props.contentPrimaryContentbox
											? <ActionContentbox
												headingElements={
													<ContentBoxElements.Title
														key="title"
														text={this.props.contentPrimaryTitle}
													/>
												}
												className={`content__contentbox
												${!this.props.contentPrimaryTitle ? "contentbox--noHeader" : ""}
											`}
											>
												{this.props.contentPrimary}
											</ActionContentbox>
											: <>
												{this.props.contentPrimaryTitle &&
												<h2>{this.props.contentPrimaryTitle}</h2>
												}
												{this.props.contentPrimary}
											</>
									}
								</Column>
								{
									this.props.layout.contentSecondarySize &&
									<Column size={this.props.layout.contentSecondarySize}>
										<ActionContentbox
											headingElements={
												<ContentBoxElements.Title
													key="title"
													text={this.props.contentSecondaryTitle}
												/>}
											className="login__contentbox contentbox--dark"
										>
											{this.props.contentSecondary}
										</ActionContentbox>
									</Column>
								}
							</Row>
						</LayoutGrid>
						<FyFooter/>
					</div>
				</div>
			</div>
		);
	}
}

export const TemplateVertical = connect<{}, DispatchProps, TemplateVerticalProps>(
	undefined,
	function mapDispatchToProps(dispatch, { activity }) {
		return {
			openImprintPage() {
				dispatch(FormEngineActions.event({
					engineEvent: {
						type: Events.eventButtonTriggered,
						payload: {
							name: "event_open-imprint-page"
						}
					},
					activityId: activity.id
				}));
			}
		};
	}
)(TemplateVerticalComponent);
