 import { Locale } from "@com.mgmtp.a12/localization/lib/main";

import * as React from "react";
import { connect } from "react-redux";
import { Icon } from "@com.mgmtp.a12/widgets";
import { FormEngineActions } from "@com.mgmtp.a12/bap-client/lib/extensions/bap-form-engine";
import { Events } from "@com.mgmtp.a12/bap-form-engine/lib/back-end/store";
import { Activity } from "@com.mgmtp.a12/bap-client/lib/core/activity";
import { AuthenticationActions, AuthenticationSelectors } from "@com.mgmtp.a12/bap-client/lib/core/authentication";
import { LocaleSelectors, Localizer } from "@com.mgmtp.a12/bap-client/lib/core/locale";
import { User } from "@com.mgmtp.a12/user-api/lib/User";
import { Container } from "@com.mgmtp.a12/bap-client/lib/core/configuration";
 import { CUSTOMER_ROLE } from "../config/config";

interface OwnProps {
	activity: Activity;
}

interface StateProps {
	locale: Locale;
	user: User | undefined;
}

interface DispatchProps {
	openCustomerPortalPage(): void;
	logoutCustomer(): void;
}

class CustomerPortalSection extends React.Component<DispatchProps & OwnProps & StateProps> {

	render(): JSX.Element {
		const localizer: Localizer = Container.config.get(Container.identifier.Localizer);
		return (
			<div className="customerPortalSection">
				<ul>
					<li><Icon>person</Icon></li>
					<li><a onClick={this.props.openCustomerPortalPage}>{localizer("customerportal.title")}</a></li>
					{
						this.props.user && this.props.user.roles.find(r => r.ident.name === CUSTOMER_ROLE)
							? <>
								<li><span>|</span></li>
								<li>{this.props.user.email}</li>
								<li><a title="Logout" onClick={this.props.logoutCustomer}><Icon>exit_to_app</Icon></a></li>
							</>
							: null
					}
				</ul>
			</div>
		);
	}
}

export const CustomerPortalSectionConnected = connect<StateProps, DispatchProps, OwnProps>(
	function mapStateToProps(state) {
		const locale = LocaleSelectors.locale()(state);
		const user = AuthenticationSelectors.user()(state);

		return {
			locale,
			user
		};
	},
	function mapDispatchToProps(dispatch, { activity }) {
		return {
			openCustomerPortalPage() {
				dispatch(FormEngineActions.event({
					engineEvent: {
						type: Events.eventButtonTriggered,
						payload: {
							name: "event_open-customer-portal-page"
						}
					},
					activityId: activity.id
				}));
			},
			logoutCustomer() {
				dispatch(AuthenticationActions.userLoggingOut());
			}
		};
	}
)
(CustomerPortalSection);
