import { FormEngineActions } from "@com.mgmtp.a12/bap-client/lib/extensions/bap-form-engine";
import { Commands, EngineState, Events } from "@com.mgmtp.a12/bap-form-engine/lib/back-end/store";
import { Middleware, MiddlewareAPI } from "redux";
import ReactGA from "react-ga";
import { AuthenticationActions } from "@com.mgmtp.a12/bap-client/lib/core/authentication";
import { CUSTOMER_ROLE } from "../config/config";
import { isValid } from "../utils";
import { SaferpayActions } from "ggw-customer-portal-common/lib/saferpay";

export const googleAnalyticsMiddleware: Middleware<{}, EngineState> = api => next => action => {
	if (FormEngineActions.event.match(action) && Events.eventButtonTriggered.match(action.payload.engineEvent)
	) {
		const event: string = action.payload.engineEvent.payload && action.payload.engineEvent.payload.name;
		const activityId = action.payload.activityId;

		if (event === "event_calculate-premium") {
			if (isValid(activityId, api)) {
				ReactGA.event({
					category: "Angebotserstellung",
					action: "Klick auf Button Prämie berechnen (zu Screen 2)"
				});
			} else {
				ReactGA.event({
					category: "Angebotserstellung",
					action: "Klick auf Button Prämie berechnen (Validierungsfehler)"
				});
			}
		} else if (event === "event_policy-holder-screen") {
			if (isValid(activityId, api)) {
				ReactGA.event({
					category: "Angebotserstellung",
					action: "Klick auf Button Versicherungsnehmer (zu Screen 3)"
				});
			} else {
				ReactGA.event({
					category: "Angebotserstellung",
					action: "Klick auf Button Versicherungsnehmer (Validierungsfehler)"
				});
			}
		} else if (event === "event_create-offer") {
			if (isValid(activityId, api)) {
				ReactGA.event({
					category: "Angebotserstellung",
					action: "Klick auf Button Angebot erstellen"
				});
			} else {
				ReactGA.event({
					category: "Angebotserstellung",
					action: "Klick auf Button Angebot erstellen (Validierungsfehler)"
				});
			}
		}
	} else if (SaferpayActions.validateAndContinuePayment.match(action)) {
		const activityId = action.payload.activityId;
		validate(activityId, api);
		if (isValid(activityId, api)) {
			ReactGA.event({
				category: "Policierung",
				action: "Klick auf Button Bezahlung"
			});
		} else {
			ReactGA.event({
				category: "Policierung",
				action: "Klick auf Button Bezahlung (Validierungsfehler)"
			});
		}
	} else if (AuthenticationActions.userLoggedIn.match(action) &&
		action.payload.user.roles.find(r => r.ident.name === CUSTOMER_ROLE)) {
		ReactGA.event({
			category: "User",
			action: "Kunde wurde eingeloggt"
		});
	} else if (AuthenticationActions.userLoggedOut.match(action)) {
		ReactGA.event({
			category: "User",
			action: "Kunde wurde ausgeloggt"
		});
	}
	return next(action);
};

function validate(activityId: string, api: MiddlewareAPI) {
	api.dispatch(FormEngineActions.event({
		engineEvent: Commands.validatePart({}),
		activityId
	}));
}
