import { FormEngineActions, FormEngineSelectors } from "@com.mgmtp.a12/bap-client/lib/extensions/bap-form-engine";
import { Commands, EngineState, Events, ModelSelectors } from "@com.mgmtp.a12/bap-form-engine/lib/back-end/store";

import { Activity, ActivityActions, ActivitySelectors } from "@com.mgmtp.a12/bap-client/lib/core/activity";
import { Dispatch, Middleware } from "redux";
import { ConnectorLocator } from "@com.mgmtp.a12/server-connector/lib/connector/ConnectorLocator";
import { runCosmoExcelComputation } from "ggw-customer-portal-common/lib/xl-a12-mapping";

import { isValid } from "../../../utils";
import _ from "lodash";

export const premiumButtonMiddleware: Middleware<{}, EngineState> = api => next => action => {
	if (FormEngineActions.event.match(action)
		// restrict to eventButtonTriggered, because eventButton also has the same event name "event_calculate-premium", but is
		// triggered after validation. We want to trigger premium calculation even if validation fails, because when the user
		// has navigated back from a later screen, there may be some invalid fields on those later screens
	) {
		const activity = ActivitySelectors.activityById(action.payload.activityId)(api.getState());
		const engineState = FormEngineSelectors.engineState(action.payload.activityId)(api.getState());
		const buttonTriggeredEvent = Events.eventButtonTriggered.match(action.payload.engineEvent);
		const navigationEvent = Events.navigationButton.match(action.payload.engineEvent);
		const documentModel = engineState && ModelSelectors.documentModel()(engineState);

		const event: string = action.payload.engineEvent.payload && action.payload.engineEvent.payload.name;

		if (activity) {
			const dataHolder = ActivitySelectors.dataHolderByDescriptor(activity.id, activity.descriptor)(api.getState());

			if (buttonTriggeredEvent && event === "event_calculate-premium") {
				api.dispatch(FormEngineActions.event({
					engineEvent: Commands.validatePart({}),
					activityId: activity.id
				}));

				if (dataHolder && isValid(activity.id, api)) {
					const data = dataHolder.data as Activity.Data.SingleDocumentData;

					api.dispatch(ActivityActions.lock({
						activityId: activity.id,
						id: `lock-${activity.id}`,
						owner: "user",
						details: {}
					}));

					// set values for coverage options that are used for premium calculation
					setCoverageOptions(data);
					// set insured sums for dinghy1 and dinghy2 if necessary. should be removed when the fields are added to the UI
					setDinghyOutboarderInsuredSums(data);

					api.dispatch(ActivityActions.setData({
						activityId: activity.id,
						data: {
							...data,
							document: {
								...data.document
							}
						}
					}));

					const serverConnector = ConnectorLocator.getInstance().getServerConnector();
					const baseUrl = serverConnector.getBaseUrl ? serverConnector.getBaseUrl() : "";

					runCosmoExcelComputation({
						documentModel: documentModel,
						data: data,
						baseUrl: baseUrl,
						api: api,
						activity: activity,
						dataHolder: dataHolder,
						productId: "firmenich"
					}).then(newDocument => {
						const currentData: any = ActivitySelectors.data(activity.id)(api.getState());
						const mergedDocument: any = _.merge(currentData.document, newDocument);
						const document = cleanDocument(mergedDocument);
						api.dispatch(ActivityActions.setData({
							activityId: activity.id,
							data: {
								...dataHolder.data,
								document: {
									...document,
									Root: {
										...document.Root,
										PremiumMetaData: {
											...data.document.Root.PremiumMetaData,
											PremiumRequestMade: true,
											ShowPremiumCalculations: true
										}
									}
								}
							}
						}));
						retriggerComputations(api.dispatch, activity.id);

						api.dispatch(FormEngineActions.command({
							engineEvent: Commands.changeScreen({ screenName: "PremiumData" }),
							activityId: activity.id
						}));

						api.dispatch(ActivityActions.unlock({
							activityId: activity.id,
							lockId: `lock-${activity.id}`
						}));
					}).catch(error => {
						api.dispatch(ActivityActions.setData({
							activityId: activity.id,
							data: {
								...dataHolder.data,
								document: {
									...data.document,
									Root: {
										...data.document.Root,
										Premium: null,
										PremiumMetaData: {
											...data.document.Root.PremiumMetaData,
											ShowPremiumCalculations: true,
											PremiumCalculationError: true
										}
									}
								}
							}
						}));
						api.dispatch(ActivityActions.unlock({
							activityId: activity.id,
							lockId: `lock-${activity.id}`
						}));
					});
				}
			} else if (navigationEvent) {
				// Clear "premium request made" flag
				if (dataHolder) {
					const data = dataHolder.data as Activity.Data.SingleDocumentData;
					api.dispatch(ActivityActions.setData({
						activityId: activity.id,
						data: {
							...data,
							document: {
								...data.document,
								Root: {
									...data.document.Root,
									PremiumMetaData: {
										...data.document.Root.PremiumMetaData,
										PremiumRequestMade: false
									}
								}
							}
						}
					}));
				}
			}
		}
	}
	return next(action);
};

function setDinghyOutboarderInsuredSums(data: Activity.Data.SingleDocumentData): void {
	const insuredSumsHull = data.document.Root.PC_Firmenich.CoverageHull.InsuredSums;
	if (insuredSumsHull && insuredSumsHull.CoverageInsuredSumHull2a) {
		data.document.Root.PC_Firmenich.RiskData.Dinghy.Dinghy1.Outboarder = [{ InsuredSum_DinghyOutboarder: insuredSumsHull.CoverageInsuredSumHull2a }];
	}
	if (insuredSumsHull && insuredSumsHull.CoverageInsuredSumHull2c) {
		data.document.Root.PC_Firmenich.RiskData.Dinghy.Dinghy2.Outboarder = [{ InsuredSum_DinghyOutboarder: insuredSumsHull.CoverageInsuredSumHull2c }];
	}
}

function setCoverageOptions(data: Activity.Data.SingleDocumentData): void {
	// HULL OPTIONS
	data.document.Root.PC_Firmenich.CoverageHull = setCoverageOptionsHull(data.document.Root.PC_Firmenich.CoverageHull);

	// LIABILITY OPTIONS
	data.document.Root.PC_Firmenich.CoverageLiability.LiabilityOptions = [
		{ CoverageLimitLiability3: "six", CoverageLimitLiability2: "six", CoverageLimitLiability1: "one" },
		{ CoverageLimitLiability3: "ten", CoverageLimitLiability2: "six", CoverageLimitLiability1: "one" }
	];

	// LEGAL OPTION
	// values are set in UI model, clear chosen option
	data.document.Root.PC_Firmenich.CoverageLegalProtection.LegalOptions.CoverageChosenOptionLegal = false;

	// ACCIDENT OPTIONS
	data.document.Root.PC_Firmenich.CoverageAccident.AccidentOptions = [
		{
			CoveragePackage: "small",
			CoverageLimitAccident3: 100000,
			CoverageLimitAccident2: 200000,
			CoverageLimitAccident1: 450000
		},
		{
			CoveragePackage: "mid",
			CoverageLimitAccident3: 200000,
			CoverageLimitAccident2: 400000,
			CoverageLimitAccident1: 900000
		},
		{
			CoveragePackage: "big",
			CoverageLimitAccident3: 400000,
			CoverageLimitAccident2: 800000,
			CoverageLimitAccident1: 1800000
		}
	];

	data.document.Root.PremiumMetaData.PremiumTotal = null;
}

interface HullData {
	CoverageTotalInsuredSumHull: number;
	HullOptions: {
		CoverageDeductibleHull: number;
	}[];
}

function setCoverageOptionsHull(hullData: HullData): HullData {
	const totalInsuredSumHull = hullData.CoverageTotalInsuredSumHull;

	switch (true) {
		case totalInsuredSumHull <= 25000:
			hullData.HullOptions = [
				{ CoverageDeductibleHull: 250 },
				{ CoverageDeductibleHull: 500 }
			];
			break;
		case totalInsuredSumHull > 25000 && totalInsuredSumHull <= 60000:
			hullData.HullOptions = [
				{ CoverageDeductibleHull: 250 },
				{ CoverageDeductibleHull: 500 },
				{ CoverageDeductibleHull: 1000 }
			];
			break;
		case totalInsuredSumHull > 60000 && totalInsuredSumHull <= 175000:
			hullData.HullOptions = [
				{ CoverageDeductibleHull: 500 },
				{ CoverageDeductibleHull: 1000 },
				{ CoverageDeductibleHull: 2000 }
			];
			break;
		case totalInsuredSumHull > 175000 && totalInsuredSumHull <= 500000:
			hullData.HullOptions = [
				{ CoverageDeductibleHull: 500 },
				{ CoverageDeductibleHull: 1500 },
				{ CoverageDeductibleHull: 2500 }
			];
			break;
		default:
			hullData.HullOptions = [];
	}
	return hullData;
}

/**
 * remove unnecessary rows from repeatables for optimist premium computation and remove empty rows from hull section if necessary
 */
function cleanDocument(document: any) {
	const cleanOptimistDocument = cleanDocumentForOptimist(document);
	const cleanLiabilityDocument = cleanLiabilityRepeatable(cleanOptimistDocument);
	return cleanHullRepeatable(cleanLiabilityDocument);
}

function cleanDocumentForOptimist(document: any) {
	const isOpti = document.Root.PC_Firmenich.RiskData.General.RD_Q8 === "opti";
	if (isOpti) {
		document.Root.PC_Firmenich.CoverageHull.HullOptions = [document.Root.PC_Firmenich.CoverageHull.HullOptions[0]];
		document.Root.PC_Firmenich.CoverageLiability.LiabilityOptions = [document.Root.PC_Firmenich.CoverageLiability.LiabilityOptions[0]];
	}
	return document;
}

interface HullOption {
	CoverageDeductibleHull: number;
	OutputsHull: {
		GrossPremiumIncludeDiscountHull: number;
	};
}

/**
 * remove 3rd row from hull repeatable if there was no xl-input and therefore no xl-output was computed
 * remove rows with unique gross premium values (only keep option with lowest deductible)
 * CANDO: solve in a more generic way in GGW-3388
 */
function cleanHullRepeatable(document: any) {
	const hullOptions: HullOption[] = document.Root.PC_Firmenich.CoverageHull.HullOptions.map((d: HullOption) => d.CoverageDeductibleHull == undefined
		? null : d)
	.filter((item: HullOption) => !(item === null ||
		item.OutputsHull.GrossPremiumIncludeDiscountHull === 0 ||
		item.OutputsHull.GrossPremiumIncludeDiscountHull === null))
	.sort((a: HullOption, b: HullOption) => a.CoverageDeductibleHull - b.CoverageDeductibleHull);
	const uniqueHullOptions = _.uniqBy(hullOptions, "OutputsHull.GrossPremiumIncludeDiscountHull");
	document.Root.PC_Firmenich.CoverageHull.HullOptions = uniqueHullOptions;
	return document;
}

interface LiabilityOption {
	CoverageChosenOptionLiability: boolean;
	OutputsLiability: {
		GrossPremiumLiability: number;
	};
}
export function cleanLiabilityRepeatable(document: any) {
	document.Root.PC_Firmenich.CoverageLiability.LiabilityOptions = document.Root.PC_Firmenich.CoverageLiability.LiabilityOptions
	.filter((option: LiabilityOption) => !(option === null ||
		option.OutputsLiability.GrossPremiumLiability === 0 ||
		option.OutputsLiability.GrossPremiumLiability === null))
	.map((option: LiabilityOption) => {
		return {
			...option,
			CoverageChosenOptionLiability: false
		};
	});
	return document;
}

function retriggerComputations(dispatch: Dispatch, activityId: string) {
	const path = [{ elementName: "Root", index: 1 }, {
		elementName: "PremiumMetaData",
		index: 1
	}, { elementName: "ComputationTrigger", index: 1 }];
	dispatch(FormEngineActions.event({
		engineEvent: Events.valueChange({
			path: path,
			value: 1
		}),
		activityId
	}));

	dispatch(FormEngineActions.event({
		engineEvent: Events.valueChange({
			path: path,
			value: 0
		}),
		activityId
	}));
}
