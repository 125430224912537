/**
 * taken from extensions/platform-server-connectors
 */
import { LoggerFactory } from "@com.mgmtp.a12/logging";
import { ConnectorLocator } from "@com.mgmtp.a12/server-connector/lib/connector/ConnectorLocator";
import { BatchRequest, BatchResponse, ErrorPayload, OperationResult } from "@com.mgmtp.a12/services-access/lib";
import { Common } from "@com.mgmtp.a12/services-access/lib/common/Request";

import { Activity } from "@com.mgmtp.a12/bap-client/lib/core/activity";

type TypeGuard<T, U extends T> = (obj: T) => obj is U;
export async function dispatchServerRequest(
	connectorLocator: ConnectorLocator,
	request: Common.Request
): Promise<string>;
export async function dispatchServerRequest<T>(
	connectorLocator: ConnectorLocator,
	request: Common.Request,
	responseChecker: TypeGuard<T | ErrorPayload, T>
): Promise<T>;
export async function dispatchServerRequest<T>(
	connectorLocator: ConnectorLocator,
	request: Common.Request,
	responseChecker?: TypeGuard<T | ErrorPayload, T>
): Promise<string | T> {

	LoggerFactory.getLogger("extensions/platform-server-connectors").info("Request", request);
	const response = await connectorLocator.getServerConnector()
		.fetchData(request.relativeUrl, request.method, request.body, request.customHeaders);
	LoggerFactory.getLogger("extensions/platform-server-connectors").info("Response", response);

	if (responseChecker === undefined) {
		return response.text();
	}

	const data = await response.json();
	if (!responseChecker(data)) {
		return Promise.reject("The server response cannot be interpreted!");
	}

	return data;
}

export async function dispatchServerBatchRequest<T extends OperationResult>(
	connectorLocator: ConnectorLocator,
	...operations: [
		[BatchRequest.BatchOperationBase, TypeGuard<OperationResult, T>?]
	]
): Promise<[T]>;
export async function dispatchServerBatchRequest<T extends OperationResult, U extends OperationResult>(
	connectorLocator: ConnectorLocator,
	...operations: [
		[BatchRequest.BatchOperationBase, TypeGuard<OperationResult, T>?],
		[BatchRequest.BatchOperationBase, TypeGuard<OperationResult, U>?]
	]
): Promise<[T, U]>;
export async function dispatchServerBatchRequest<T extends OperationResult>(
	connectorLocator: ConnectorLocator,
	...operations: [BatchRequest.BatchOperationBase, TypeGuard<OperationResult, T>?][]
): Promise<OperationResult[]> {
	const request = BatchRequest.build({ request: operations.map(([operation]) => operation) });
	const response = await dispatchServerRequest(connectorLocator, request, BatchResponse.isInstance);

	if (BatchResponse.hasErrors(response)) {
		return Promise.reject(createActivityError(response));
	}

	const defaultChecker = (o: OperationResult): o is OperationResult => true;
	if (!verifyResults(response.results, operations.map(([, checker = defaultChecker]) => checker))) {
		return Promise.reject("The server response cannot be interpreted!");
	}

	return response.results;
}

function verifyResults<T extends OperationResult>(
	results: OperationResult[],
	checkers: TypeGuard<OperationResult, T>[]
): results is T[] {
	return checkers.every((check, i) => check(results[i]));
}

/** @internal */
function createActivityError(response: BatchResponse): Activity.Error.Base {
	const error = {
		...response.exceptions,
		errorCode: "PLATFORM-SERVER-CONNECTOR-BATCH-OPERATION"
	};

	return error;
}
