import { FormEngineActions } from "@com.mgmtp.a12/bap-client/lib/extensions/bap-form-engine";
import { Commands, EngineState, Events } from "@com.mgmtp.a12/bap-form-engine/lib/back-end/store";

import { Activity, ActivityActions, ActivitySelectors } from "@com.mgmtp.a12/bap-client/lib/core/activity";
import { Middleware } from "redux";
import { isValid } from "../../../utils";

export const policyHolderButtonMiddleware: Middleware<{}, EngineState> = api => next => action => {
	if (FormEngineActions.event.match(action) && Events.eventButtonTriggered.match(action.payload.engineEvent)) {
		const activity = ActivitySelectors.activityById(action.payload.activityId)(api.getState());

		const event: string = action.payload.engineEvent.payload && action.payload.engineEvent.payload.name;

		if (activity && event) {
			if (event === "event_policy-holder-screen") {
				const dataHolder = ActivitySelectors.dataHolderByDescriptor(activity.id, activity.descriptor)(api.getState());

				api.dispatch(FormEngineActions.event({
					engineEvent: Commands.validatePart({}),
					activityId: activity.id
				}));

				if (dataHolder && isValid(activity.id, api)) {
					const data = dataHolder.data as Activity.Data.SingleDocumentData;

					if (!data.document.Root.OfferData || !data.document.Root.OfferData.InceptionDate) {
						api.dispatch(ActivityActions.setData({
							activityId: activity.id,
							data: {
								...data,
								document: {
									...data.document,
									Root: {
										...data.document.Root,
										OfferData: {
											InceptionDate: new Date()
										}
									}
								}
							}
						}));
					}

					api.dispatch(FormEngineActions.command({
						engineEvent: Commands.changeScreen({ screenName: "PolicyHolder" }),
						activityId: activity.id
					}));
				}
			}
		}
	}
	return next(action);
};
