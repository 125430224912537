import { SagaIterator } from "redux-saga";
import { call, put, select, take, takeEvery, takeLatest } from "redux-saga/effects";

import { Activity, ActivityActions, ActivitySelectors } from "@com.mgmtp.a12/bap-client/lib/core/activity";
import { AuthenticationActions } from "@com.mgmtp.a12/bap-client/lib/core/authentication";
import { Route } from "./types";
import {
	offerNotFoundRoute,
	offerRouteName,
	pageAlreadyPayedRoute,
	pageNotFoundRoute,
	pageOfferExpiredRoute,
	routes
} from "./routes";
import { FormEngineActions } from "@com.mgmtp.a12/bap-client/lib/extensions/bap-form-engine";
import { Commands } from "@com.mgmtp.a12/bap-form-engine/lib/back-end/store";
import { Action } from "typescript-fsa";
import { LoggerFactory } from "@com.mgmtp.a12/logging";
import {
	findCurrentRoute,
	getOfferIdFromOfferRoute,
	hasUserRequiredRole,
	isMatching,
	offerRoutePattern,
	sanitizeRoute
} from "./utils";
import { contextPath } from "../utils";
// @ts-ignore
import _ from "lodash";
import { CustomSelectors } from "ggw-customer-portal-common/lib/force-login";
import { activityInitializers } from "./activityInitializers";
import Descriptor = Activity.Descriptor;
import { SaferpayActions } from "ggw-customer-portal-common/lib/saferpay";
import { ConnectorLocator } from "@com.mgmtp.a12/server-connector/lib/connector/ConnectorLocator";

const logger = LoggerFactory.getLogger("routing");

export function* watchLastActivatedActivityChanged(): SagaIterator {
	yield takeLatest(
		[ActivityActions.push, ActivityActions.cancel],
		function* () {
			yield createRoute();
			yield runActivityInitializers();
		}
	);
}

function* runActivityInitializers(): SagaIterator {
	let activity: Activity | undefined = yield select(ActivitySelectors.latestActivity());
	while (!activity) {
		yield take(ActivityActions.push);
		activity = yield select(ActivitySelectors.latestActivity());
	}
	for (const initer of activityInitializers) {
		if (initer.matchConditions.every(matchCondition => Descriptor.evaluate(matchCondition, activity!.descriptor))) {
			yield call(initer.initializer, activity);
		}
	}
}

// see https://discourse.mgm-edv.de/t/implementing-a-router-for-bap-client/272/3
function* createRoute() {
	let activity: Activity | undefined = yield select(ActivitySelectors.latestActivity());
	while (!activity) {
		yield take(ActivityActions.push);
		activity = yield select(ActivitySelectors.latestActivity());
	}
	const route = routes.find((r: Route) => {
		return isMatching(r, activity!);
	});
	if (!route) {
		logger.error(`CreateRouteError: no matching route found for activity ${activity.id}.`);
		return;
	}

	const absoluteUrl: URL = new URL(`${contextPath}/${route.route}`, location.origin);
	if (!route.match(locationPathAsRoute())) {
		history.pushState({}, "", absoluteUrl.href);
	}
}

function locationPathAsRoute(): string {
	const path = location.pathname;
	if (path.startsWith(contextPath) && path.length > contextPath.length) {
		return path.substring(contextPath.length + 1);
	} else {
		return "";
	}
}

export function* watchScreenChanged(): SagaIterator {
	yield takeLatest(
		FormEngineActions.command,
		createOfferScreenRoute
	);
}

// see https://discourse.mgm-edv.de/t/implementing-a-router-for-bap-client/272/3
function* createOfferScreenRoute(action: Action<FormEngineActions.FormEngineEventActions>) {
	if (!Commands.changeScreen.match(action.payload.engineEvent)) {
		return;
	}

	let activity: Activity | undefined = yield select(ActivitySelectors.latestActivity());
	while (!activity) {
		yield take(ActivityActions.push);
		activity = yield select(ActivitySelectors.latestActivity());
	}
	const dataHolder = yield select(ActivitySelectors.dataHolderByDescriptor(activity.id, activity.descriptor));

	if (!dataHolder) {
		return;
	}

	const screenName = action.payload.engineEvent.payload.screenName;

	const route = routes.find((r: any) => {
		return isMatching(r, activity!, screenName);
	});

	if (!route) {
		logger.info(`CreateScreenRoute: no matching screen route found for activity ${activity.id}.`);
		return;
	}

	// add offer id to url
	const { offerId } = activity.descriptor;
	const encodedRoute = route.route.replace(offerRouteName, offerId ? `${offerRouteName}/${offerId}` : "");

	const absoluteUrl: URL = new URL(`${contextPath}/${encodedRoute}`, location.origin);
	const withoutSearch = new URL(location.href);
	withoutSearch.search = "";
	if (absoluteUrl.href !== withoutSearch.href) {
		history.pushState({}, "", absoluteUrl.href);
	}
}

export function* watchLoadingApplication(): SagaIterator {
	yield takeEvery(AuthenticationActions.userLoggedIn, triggerRouteApplication);
	// make page reload when browser navigation buttons are used
	yield takeLatest(AuthenticationActions.userLoggedIn, registerPopstateHandler);
}

function* triggerRouteApplication(action: Action<AuthenticationActions.UserLoggedInPayload>) {
	const currentRoute = findCurrentRoute();
	const forceLogin = yield select(CustomSelectors.forceLogin());
	if (hasUserRequiredRole(currentRoute, action.payload.user) && !forceLogin) {
		yield call(applyRoute);
	}
}

// see https://discourse.mgm-edv.de/t/implementing-a-router-for-bap-client/272/3
function* applyRoute() {
	if (!location.pathname.startsWith(contextPath)) {
		return;
	}
	const fullRoute = sanitizeRoute(location.pathname.substring(contextPath.length + 1));

	if (!fullRoute || fullRoute.length === 0) {
		// not starting activities if the base URL is called
		return;
	}
	const route = routes.find((r: Route) => {
		// fullRoute is for example 'offer/63/payment'
		if (fullRoute.startsWith(offerRouteName) && fullRoute !== offerRouteName) {
			const cleanRoute = fullRoute.replace(offerRoutePattern, "offer/");
			return r.route === cleanRoute;
		}
		return r.route === fullRoute.toLowerCase();
	});

	// show default page when route isn't defined
	if (!route) {
		logger.info("Given route isn't defined");
		yield put(ActivityActions.create({ activityDescriptor: pageNotFoundRoute.descriptor }));
		return;
	}

	if (fullRoute.startsWith(offerRouteName) && fullRoute !== offerRouteName) {
		yield call(applyOfferScreenRoute, route, fullRoute);
	} else {
		yield put(ActivityActions.create({ activityDescriptor: route.descriptor }));
		if (route.screenActions) {
			const activity: Activity = yield select(ActivitySelectors.latestActivity());
			yield call(route.screenActions, activity);
		}
	}
}

function* applyOfferScreenRoute(route: Route, fullRouteName: string) {
	if (!route.screenName) {
		logger.info("No route found for this screen.");
		return;
	}

	const offerId = getOfferIdFromOfferRoute(fullRouteName);

	if (!offerId) {
		logger.info("No offer id provided in url.");
		yield put(ActivityActions.create({ activityDescriptor: pageNotFoundRoute.descriptor }));
		return;
	}

	// create offer activity and load data
	yield put(ActivityActions.create({
		activityDescriptor: {
			...route.descriptor,
			offerId
		}
	}));

	const activity: Activity = yield select(ActivitySelectors.latestActivity());
	// wait for setData activity
	while (true) {
		const action: Action<ActivityActions.SetDataPayload> = yield take(ActivityActions.setData);
		if (action.payload.activityId === activity.id) {
			break;
		}
	}

	const dataHolder: Activity.DataHolder =
		yield select(ActivitySelectors.dataHolderByDescriptor(activity.id, activity.descriptor));

	// if no document could be found, display error page
	if (_.isEmpty((dataHolder.data as any).document)) {
		logger.info("No document found for given offerId");
		yield put(ActivityActions.create({ activityDescriptor: offerNotFoundRoute.descriptor }));
		return;
	}

	const document = (dataHolder.data as any).document;
	if (document.Root && document.Root.Payment && document.Root.Payment.Payed) {
		logger.info("Payment already executed for given offerId");
		yield put(ActivityActions.create({ activityDescriptor: pageAlreadyPayedRoute.descriptor }));
		return;
	}

	if (document.Root && document.Root.OfferValidityEndDate && offerExpired(document.Root.OfferValidityEndDate)) {
		logger.info("offer already expired");
		yield put(ActivityActions.create({ activityDescriptor: pageOfferExpiredRoute.descriptor }));
		return;
	}

	if (route.screenActions) {
		yield call(route.screenActions, activity);
	}

	yield put(FormEngineActions.command({
		engineEvent: Commands.changeScreen({ screenName: route.screenName }),
		activityId: activity.id
	}));

}

function offerExpired(validityEndDate: Date): boolean {
	return (new Date().getTime() >= validityEndDate.getTime());
}

/**
 * Dispatch action to trigger loading of payment wall and wait until request is done
 */
export function* createSaferpayPage(activity: Activity) {
	yield put(SaferpayActions.saferpayPageRequested({
		activityId: activity.id,
		connectorLocator: ConnectorLocator.getInstance()
	}));

	// wait for payment request to finish
	while (true) {
		const action: Action<ActivityActions.SetDataPayload> = yield take(ActivityActions.setData);
		if ((action.payload.data as any).payment) {
			break;
		}
	}
}

export function* triggerPaymentExecutionSaferpay(activity: Activity) {
	yield put(SaferpayActions.executePaymentRequested({ activityId: activity.id, connectorLocator: ConnectorLocator.getInstance() }));
}

function registerPopstateHandler() {
	onpopstate = () => {
		location.reload();
	};
}
