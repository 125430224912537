import { Activity, ActivitySelectors } from "@com.mgmtp.a12/bap-client/lib/core/activity";
import { DataLoader } from "@com.mgmtp.a12/bap-client/lib/core/data";
import { User } from "@com.mgmtp.a12/user-api/lib/User";
import { DocumentModel, DocumentServiceFactory } from "@com.mgmtp.a12/kernel-facade";
import { ConnectorLocator } from "@com.mgmtp.a12/server-connector/lib/connector/ConnectorLocator";
import { dispatchServerBatchRequest } from "./dispatchServerRequest";
import { DocumentOperation, ListDocumentsResult } from "@com.mgmtp.a12/services-access/lib";
import { Container } from "@com.mgmtp.a12/bap-client/lib/core/configuration";
import { LocaleProvider } from "@com.mgmtp.a12/bap-client/lib/core/locale";

const documentService = new DocumentServiceFactory().getDocumentService();

/**
 * {@link DataLoader} responsible for loading saved BaywaPv document.
 */
export class FyDocumentDataLoader implements DataLoader<Activity.Data.SingleDocumentData> {
	readonly name = "BaywaPvDocumentDataLoader";
	readonly meta: DataLoader.Meta = {
		name: "BaywaPvDocumentDataLoader",
		matchConditions: [
			{
				key: "model",
				mustEqual: "FyCustomerPortal"
			},
			{
				key: "offerId",
				isSet: true
			}
		]
	};

	constructor(
		protected documentDataLoader: DataLoader<Activity.Data.SingleDocumentData>,
		protected connectorLocator: ConnectorLocator
	) { }

	private operationCounter = 0;
	private localeProvider = Container.config.get<LocaleProvider>(Container.identifier.LocaleProvider).get();

	async load(
		activity: Activity,
		user: User,
		documentModel?: DocumentModel
	): Promise<Activity.Data.SingleDocumentData> {
		if (!documentModel) {
			return Promise.reject("No document model was provided!");
		}

		const { filter: fulltext, pagination, sorting } = ActivitySelectors.listSlice(activity);
		const lang = this.localeProvider.language;
		const filters = [`Root.CosmoOfferId:${activity.descriptor.offerId}`];

		const operation: DocumentOperation.ListDocumentsOperation = {
			operation: "LIST_DOCUMENTS",
			id: `${this.name}-${this.operationCounter++}`,
			parameters: {
				documentModelName: documentModel.name,
				filterSpec: { filters, fulltext, lang: "" },
				pageSpec: { offset: pagination.pageNumber * pagination.pageSize, limit: pagination.pageSize },
				sortSpec: sorting
					? [{ order: `${sorting.path} ${sorting.order}`, lang }]
					: []
			}
		};

		const [{ result: { entries } }] = await dispatchServerBatchRequest(
			this.connectorLocator,
			[operation, ListDocumentsResult.isInstance]
		);

		if (entries.length === 0) {
			return { document: {} as Activity.Data.Document };
		}

		const { document, docRef: id, documentModelName: modelId } = entries[0];
		return { document: { ...documentService.parseDates(document, documentModel), id, modelId } };
	}

	save(
		activityDescriptor: Activity.Descriptor,
		data: Activity.Data.SingleDocumentData,
		user: User,
		documentModel?: DocumentModel
	): Promise<{}> {
		return this.documentDataLoader.save(activityDescriptor, data, user, documentModel);
	}

	delete(
		activityDescriptor: Activity.Descriptor,
		data: Activity.Data.SingleDocumentData,
		user: User,
		documentModel?: DocumentModel
	): Promise<void> {
		return this.documentDataLoader.delete(activityDescriptor, data, user, documentModel);
	}

	canHandle(activityDescriptor: Activity.Descriptor): boolean {
		return DataLoader.canHandle(this.meta, activityDescriptor);
	}
}
