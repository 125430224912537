import {
	ApplicationActions,
	ApplicationFactories,
	ApplicationReducers,
	ModuleRegistryProvider
} from "@com.mgmtp.a12/bap-client/lib/core/application";
import { DataHandlers } from "@com.mgmtp.a12/bap-client/lib/core/data";

import { AuthenticationReducers, AuthenticationSelectors } from "@com.mgmtp.a12/bap-client/lib/core/authentication";
import { ApplicationModel, ModelReducers } from "@com.mgmtp.a12/bap-client/lib/core/model";
import * as model from "./app_model.json";

import { Container } from "@com.mgmtp.a12/bap-client/lib/core/configuration";
import { LocaleActions, LocaleProvider, LocaleReducers } from "@com.mgmtp.a12/bap-client/lib/core/locale";
import { configure as configurePlatformServerConnectors } from "@com.mgmtp.a12/bap-client/lib/extensions/platform-server-connectors";
import { CRUDFactories } from "@com.mgmtp.a12/bap-client/lib/extensions/crud";

import {
	bapFormEngineDataReducers,
	bapFormEngineMiddlewares,
	bapFormEngineSagas
} from "@com.mgmtp.a12/bap-client/lib/extensions/bap-form-engine";
import { ActivityReducers } from "@com.mgmtp.a12/bap-client/lib/core/activity";
import { InspectorReducer } from "@com.mgmtp.a12/bap-client/lib/extensions/inspector";
import { combineReducers, Reducer, StoreEnhancer } from "redux";
import { NotificationReducers } from "@com.mgmtp.a12/bap-client/lib/core/notification";
import { UserManagementReducers } from "@com.mgmtp.a12/bap-client/lib/core/user-management";

import PRODUCT_PAGE_MODULES from "./modules";
import { createViewComponentProvider } from "./containerFactory";
import { ApplicationSetup } from "@com.mgmtp.a12/bap-client/lib/core/application/internal/factories";
import { View } from "@com.mgmtp.a12/bap-client/lib/core/view/internal/view";
import { contextPath } from "./utils";
import { FyDocumentDataLoader } from "./modules/offer-creation/document-data-loader/FyDocumentDataLoader";
import { CustomReducers } from "ggw-customer-portal-common/lib/force-login";
import { DeepLinkingFactories } from "./routing/factories";
import { isBaseUrl } from "./routing/utils";
import { openCustomerPortalPageMiddleware } from "./modules/customer-portal/openCustomerPortalPageMiddleware";
import { createBoatDBMiddleware, createReloadMasterAfterLoginMiddleware } from "ggw-customer-portal-common/lib/boatdb/boatDBMiddleware";
import { Locale } from "@com.mgmtp.a12/localization/lib/main";
import { MODEL_NAME } from "./config/config";
import { LogoutSagaFactories } from "ggw-customer-portal-common/lib/logout";
import { googleAnalyticsMiddleware } from "./google-analytics/middleware";
import { firmenichBoatDb } from "./modules/offer-creation/firmenichBoatDb";

const appModel = model as ApplicationModel;

export function setup(delay: number): {
	config: ApplicationSetup,
	viewComponentProvider: View.ComponentProvider,
	initialStoreActions(): Promise<void>
} {
	const platformServerConnectors = configurePlatformServerConnectors({
		serverURL: `${contextPath}/api`,
		localeProvider: () => Container.config.get<LocaleProvider>(Container.identifier.LocaleProvider).get(),
		userProvider: () => {
			if (config === undefined) {
				throw new Error("broken setup");
			}
			return AuthenticationSelectors.user()(config.store.getState());
		}
	});

	PRODUCT_PAGE_MODULES.forEach(m => ModuleRegistryProvider.getInstance().addModule(m));

	const dataHandlers: DataHandlers = {
		dataEditors: [],
		dataLoaders: [
			new FyDocumentDataLoader(
				platformServerConnectors.loaders.documentDataLoader,
				platformServerConnectors.services.connectorLocator
			),
			platformServerConnectors.loaders.overviewDataLoader,
			platformServerConnectors.loaders.documentDataLoader
		],
		modelLoaders: [
			platformServerConnectors.loaders.modelLoader
		],
		kernelCodeLoaders: platformServerConnectors.loaders.kernelCodeLoader
			? [platformServerConnectors.loaders.kernelCodeLoader]
			: [],
		dataProviders: [
			ApplicationFactories.createEmptyDocumentDataProvider()
		]
	};

	const activityReducers = ActivityReducers.createActivityReducers({
		dataReducers: [
			...bapFormEngineDataReducers
		]
	});

	const activityReducer: Reducer<object> = (s, a) => {
		const x = InspectorReducer.activities(s, a);
		if (x !== s) {
			return x;
		}
		return activityReducers(s, a);
	};

	const appReducer = combineReducers<object>({
		notifications: NotificationReducers.notifications,
		authentication: AuthenticationReducers.authentication,
		models: ModelReducers.models,
		activities: activityReducer,
		locale: LocaleReducers.locale,
		application: ApplicationReducers.application,
		userManagement: UserManagementReducers.userManagement,
		globals: CustomReducers.globals
	});

	const config = ApplicationFactories.createApplicationSetup({
		model: appModel as ApplicationModel,
		dataHandlers,
		appReducer,
		storeEnhancer: enableReduxDevTools(),
		overridePlatformSagas: [
			...platformServerConnectors.sagas
		],
		customSagas: [
			...DeepLinkingFactories.createSagas(),
			...CRUDFactories.createSagas(),
			...bapFormEngineSagas,
			...LogoutSagaFactories.createSagas("https://www.firmenich-yachtversicherung.de")
		],
		additionalMiddlewares: [
			...bapFormEngineMiddlewares,
			openCustomerPortalPageMiddleware,
			createBoatDBMiddleware(firmenichBoatDb),
			createReloadMasterAfterLoginMiddleware(firmenichBoatDb),
			googleAnalyticsMiddleware,
			CRUDFactories.createCRUDMiddleware()
		],
		preComputeNewDocuments: true,
		setupActions: [
			LocaleActions.set(Locale.fromString("de_DE")),
			...(isBaseUrl()
				? [ApplicationActions.startMainActivityRequested({
						instance: "__NEW__",
						model: MODEL_NAME
					}
				)]
				: [])
		]
	});

	const viewComponentProvider = createViewComponentProvider(config.store);

	return {
		config,
		viewComponentProvider,
		initialStoreActions: async () => {
			platformServerConnectors.restoreAuthenticationState(config.store.dispatch);
		}
	};
}

declare var window: Window & { __REDUX_DEVTOOLS_EXTENSION__?(): StoreEnhancer };

/**
 * Trick to enable Redux DevTools with TS: see https://www.npmjs.com/package/redux-ts
 */
function enableReduxDevTools(): StoreEnhancer | undefined {
	return process.env.NODE_ENV !== "production" && typeof window !== "undefined"
	&& window.__REDUX_DEVTOOLS_EXTENSION__ !== undefined
		? window.__REDUX_DEVTOOLS_EXTENSION__()
		: undefined;
}
