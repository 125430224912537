import * as React from "react";
import { CollapsiblePanel } from "@com.mgmtp.a12/widgets";

interface CollapsiblePanelProps {
	title: string;
	content: JSX.Element;
	open: boolean;
}

export class Panel extends React.Component<CollapsiblePanelProps, { open?: boolean; }> {
	constructor(props: CollapsiblePanelProps) {
		super(props);
		this.state = {
			open: props.open
		};
	}
	render(): React.ReactNode {
		return <CollapsiblePanel
			title={this.props.title}
			className="collapsiblePanel--background"
			onClick={() => { this.setState(state => ({ open: !state.open })); }}
		>
			{
				this.state.open
					? this.props.content
					: undefined
			}
		</CollapsiblePanel>;
	}
}
