import { LocalizedResourceBundle } from "@com.mgmtp.a12/bap-client/lib/core/locale";

import { resources as de } from "./resources-de";
import { resources as en } from "./resources-en";

/**
 * Translations for static texts. Please note that the fallback language is en.
 */
const res: LocalizedResourceBundle = {
	de,
	en
};

export default res;
