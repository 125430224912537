import * as React from "react";

import { View, ViewSelectors } from "@com.mgmtp.a12/bap-client/lib/core/view";
import {
	FormEngineViews,
	FormEngineStateAdapter
} from "@com.mgmtp.a12/bap-client/lib/extensions/bap-form-engine";

import { Wizard, Icon } from "@com.mgmtp.a12/widgets";
import { connect } from "react-redux";
import { EngineState, DataSelectors } from "@com.mgmtp.a12/bap-form-engine/lib/back-end/store";
import { Model, ModelSelectors } from "@com.mgmtp.a12/bap-client/lib/core/model";
import { DefaultFormModelMap, FormModelMap, InputMap } from "@com.mgmtp.a12/bap-form-engine/lib/view";
import { FormModel } from "@com.mgmtp.a12/bap-form-engine/lib/models";
import { DefaultInputModelMap } from "@com.mgmtp.a12/bap-form-engine/lib/view/internal/configuration/Defaults";
import { Container } from "@com.mgmtp.a12/bap-client/lib/core/configuration";
import { LocaleProvider } from "@com.mgmtp.a12/bap-client/lib/core/locale";
import { CustomComponents } from "../widgets/CustomFormModelComponents";
import { CustomInputComponents } from "../widgets/CustomInputComponents";
import { getAnnotation } from "../widgets/utils";
import { Activity } from "@com.mgmtp.a12/bap-client/lib/core/activity";
import { firmenichBoatDb } from "../firmenichBoatDb";

type OwnProps = View;

interface StateProps {
	formModel?: Model;
	currentScreenName?: string;
	showPremiumCalculations?: boolean;
	paymentWithInvoice?: boolean;
	premiumCalculationSuccessful?: boolean;
	offerRequestSuccessful?: boolean;
	offerRequestSent?: boolean;
	offerRequestMultiRegistration?: boolean;
	paymentFailed?: boolean;
	manualOfferCreation?: boolean;
}

interface FormViewProps {
	screenIcons: {
		[key: string]: string
	};
	invisibleScreens: string[];
	scrollToTop(): void;
}

interface FormViewState {
	activeScreen: number;
}

export class FormViewComponent extends React.Component<StateProps & OwnProps & FormViewProps, FormViewState> {
	// TODO: can probably be removed with A12E-1741
	private controlScrollToRef = React.createRef<HTMLDivElement>();

	constructor(props: StateProps & OwnProps & FormViewProps) {
		super(props);

		this.state = {
			activeScreen: 0
		};
	}

	componentDidMount = () => {
		this.props.scrollToTop();
	}

	componentDidUpdate(prevProps: StateProps) {
		if (this.props.showPremiumCalculations !== prevProps.showPremiumCalculations
			&& this.props.showPremiumCalculations
			&& this.controlScrollToRef.current) {
			this.controlScrollToRef.current.scrollIntoView(true);
		}
		const currentScreenNamesUndefined = !this.props.currentScreenName || !prevProps.currentScreenName;
		if (!currentScreenNamesUndefined && this.props.currentScreenName !== prevProps.currentScreenName) {
			this.props.scrollToTop();
		}
	}

	// components were left in FormContainer because they depend on this.props.
	MultiColumnSectionComponent =
		(props: FormModelMap.FormModelComponentProps<FormModel.MultiColumnSection>): JSX.Element | null => {
			return CustomComponents.MultiColumnSectionComponent(
				props,
				this.props.showPremiumCalculations || false,
				this.props.premiumCalculationSuccessful || false,
				this.props.paymentWithInvoice || false
			);
		}

	ControlGridComponent =
		(props: FormModelMap.FormModelComponentProps<FormModel.ControlGrid>): JSX.Element | null => {
			const jumpToAnnotation = getAnnotation(props.modelElement, "scrollToElement");

			// add wrapper element with ref for scroll handling to control grid
			// this is used to scroll the premium section into the screen after the calculation was done
			return (
				!jumpToAnnotation
					? <DefaultFormModelMap.ControlGrid.component {...props} />
					: <div className="scrollToElement" ref={this.controlScrollToRef}>
						<DefaultFormModelMap.ControlGrid.component {...props} />
					</div>
			);
		}

	SectionComponent =
		(props: FormModelMap.FormModelComponentProps<FormModel.Section>): JSX.Element | null => {
			return CustomComponents.SectionComponent(
				props,
				this.props.activity,
				this.props.offerRequestSuccessful || false,
				this.props.offerRequestSent || false,
				this.props.offerRequestMultiRegistration || false,
				this.props.paymentFailed || false,
				this.props.manualOfferCreation || false
			);
		}

	inputElementMap: InputMap = {
		...DefaultInputModelMap,
		NumberInput: { component: CustomInputComponents.NumberInputComponent }
	};

	render() {
		if (!this.props.formModel) {
			return null;
		}

		const language = Container.config.get<LocaleProvider>(Container.identifier.LocaleProvider).get().language;

		// tslint:disable-next-line:no-any
		const _screens: [FormModel.Screen] = (this.props.formModel as any).data.screens;
		const screens = _screens.filter(screen => this.props.invisibleScreens.indexOf(screen.name) === -1);
		const currentScreenIndex = screens.findIndex((d: FormModel.Screen) => d.name === this.props.currentScreenName);
		const isLastElementSelected = currentScreenIndex === (screens.length - 1);

		const formModelMap: FormModelMap = {
			...DefaultFormModelMap,
			Section: { component: this.SectionComponent },
			ExpressionCell: { component: CustomComponents.ExpressionCellComponent },
			MultiColumnSection: { component: this.MultiColumnSectionComponent },
			ControlGrid: { component: this.ControlGridComponent }
		};

		return (
			<>
				<Wizard className={isLastElementSelected ? "wizard--lastElementSelected" : ""}>
					{
						// tslint:disable-next-line:no-any
						screens.map((screen: any, index: number) => {
							return <Wizard.Step
								key={`wizardElement-${index}`}
								// tslint:disable-next-line:no-any
								label={screen.title.text.find((d: any) => d.lang === language).value}
								selected={this.props.currentScreenName === screen.name}
								nonInteractive
								icon={<Icon>{this.props.screenIcons[screen.name]}</Icon>}
								className={currentScreenIndex >= index ? "wizard__step--visited" : ""}
							/>;
						})
					}
				</Wizard>
				<div className="offerForm" style={{position: "relative"}}>
					{[0, 1, 3].includes(currentScreenIndex) && <div className="phoneBox">
						<span>Bei Unklarheiten im Umgang mit der Online-Anfrage melden Sie sich gerne unter</span>
						<a href="tel:+49403281014701">040 328 101 - 4701</a>
					</div>}
					<FormEngineViews.FormEngine
						name="ContractFormEngine"
						externalEnumerationProvider={firmenichBoatDb.externalEnumerationProvider}
						activity={this.props.activity}
						formModelMap={formModelMap}
						inputElementMap={this.inputElementMap}
					/>
				</div>
			</>
		);
	}
}

export const FormContainer = connect<StateProps, {}, OwnProps, EngineState>(
	function mapStateToProps(state, ownProps) {
		const engineState = FormEngineStateAdapter.mapStateToProps(state, ownProps);

		const { activity, modelDescriptors } = ownProps;

		const sceneReference = ViewSelectors.sceneReferenceByActivityId(activity.id)(state);

		if (sceneReference === undefined) {
			return {};
		}

		let formModelDescriptor: Model.Descriptor | undefined;
		if (modelDescriptors !== undefined) {
			formModelDescriptor = modelDescriptors.find(model => model.modelType === "form");
		}
		const formModel =
			ModelSelectors.formModel({ sceneReference, modelDescriptor: formModelDescriptor })(state);

		if (formModel === undefined || formModel.loadingState !== "loaded" || !engineState.state) {
			return {};
		}

		// check if value of technical field ShowPremiumCalculations is true in document
		// tslint:disable-next-line:no-any
		const document: any = DataSelectors.document()(engineState.state);
		const showPremiumCalculations = document && document.Root && document.Root.PremiumMetaData
			&& document.Root.PremiumMetaData.ShowPremiumCalculations;
		const premiumCalculationSuccessful = document && document.Root && document.Root.PremiumMetaData
			&& !document.Root.PremiumMetaData.PremiumCalculationError;

		const paymentWithInvoice = document && document.Root && document.Root.PaymentWithInvoice;

		const offerRequestSuccessful = document && document.Root && document.Root.OfferRequestMetaData
			&& !document.Root.OfferRequestMetaData.OfferRequestError;

		const offerRequestSent = document && document.Root && document.Root.OfferRequestMetaData
			&& document.Root.OfferRequestMetaData.OfferRequestSent;

		const offerRequestMultiRegistration = document && document.Root && document.Root.OfferRequestMetaData
			&& document.Root.OfferRequestMetaData.OfferRequestMultiRegistration;

		const currentScreenName = engineState.state.ui.screenLocation[0].locationPath[0].elementName;

		const { dataHolders = [] } = activity;
		const activityDataHolder = dataHolders.find(Activity.DataHolder.hasDescriptor(activity.descriptor));

		const paymentFailed = activityDataHolder && (activityDataHolder.data as any).paymentFailed;

		const manualOfferCreation = document?.Root?.PremiumMetaData?.ManualOfferCreation;

		return {
			formModel,
			currentScreenName,
			showPremiumCalculations,
			premiumCalculationSuccessful,
			paymentWithInvoice,
			offerRequestSuccessful,
			offerRequestSent,
			offerRequestMultiRegistration,
			paymentFailed,
			manualOfferCreation
		};
	}
)(FormViewComponent);
