import * as React from "react";
import { contextPath } from "../utils";

export class FyFooter extends React.Component<{}> {

	render(): JSX.Element {
		return (
			<div id="footer-wrapper">
				<section id="footer" className="container">
					<div className="row">
						<div className="12u">
							<section>
								<nav className="xxx mod_customnav block" id="FooterMenu">
									<a href="https://firmenich-yacht.de/angebot/angebotsformular.html#skipNavigation4" target="_blank" className="invisible">Navigation
										überspringen</a>
									<ul className="level_1" role="menubar">
										<li className="first">
											<a href="https://firmenich-yacht.de/startseite.html" target="_blank" title="FIRMENICH Yachtversicherungen" className="first" role="menuitem">Startseite</a>
										</li>
										<li>
											<a href="https://firmenich-yacht.de/schadenservice/pflichtinformationen.html" target="_blank" title="Pflichtinformationen" role="menuitem">Pflichtinformationen</a>
										</li>
										<li>
											<a href="https://firmenich-yacht.de/kontakt/nutzungsbedingungen.html" target="_blank" title="Nutzungsbedingungen" role="menuitem">Nutzungsbedingungen</a>
										</li>
										<li><a href="https://firmenich-yacht.de/impressum.html" target="_blank" title="Impressum" role="menuitem">Impressum</a></li>
										<li className="last"><a href="https://firmenich-yacht.de/datenschutz.html" target="_blank" title="Datenschutz" className="last"
																role="menuitem">Datenschutz</a></li>
									</ul>
								</nav>
								<div className="mod_randomImage block" id="MitgliedIm">
									<figure className="image_container">
										<img src={`${contextPath}/images/MitgliedIm.png`} width="294" height="204" alt="MitgliedIm.png"/>
									</figure>
								</div>
								<div id="Copyright">
									© FIRMENICH | Alle Rechte vorbehalten<br/>
									Ein Unternehmen der Gossler, Gobert &amp; Wolters Gruppe
								</div>
							</section>
						</div>

					</div>

				</section>
			</div>
		);
	}
}
