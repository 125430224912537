import ReactGA from "react-ga";
import { getOfferIdFromOfferRoute, removeLeadingSlash, removeTrailingSlash } from "../routing/utils";
import { offerRouteName } from "../routing/routes";

export function trackRoute() {
	const pathname = getPathnameForGoogleAnalytics(location.pathname);
	ReactGA.set({ page: pathname });
	ReactGA.pageview(pathname);
}

export function getPathnameForGoogleAnalytics(route: string): string {
	const pathname = removeTrailingSlash(removeLeadingSlash(route));
	if (pathname.startsWith(offerRouteName)) {
		const offerId = getOfferIdFromOfferRoute(pathname);
		const newPathname = pathname.replace(`${offerId}`, "offerId");
		return newPathname;
	}
	return pathname;
}

/**
 * Read trackingId from metadata in head of index.html
 */
export function getTrackingId(): string {
	const metaData: any = document.head.querySelector("[name='tracking.id'][content]");
	if (metaData) {
		return metaData.content || "";
	}
	return "";
}
