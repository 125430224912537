import { FormEngineActions, FormEngineSelectors } from "@com.mgmtp.a12/bap-client/lib/extensions/bap-form-engine";
import { ActivitySelectors, Activity, ActivityActions } from "@com.mgmtp.a12/bap-client/lib/core/activity";
import { EngineState, Commands, ModelSelectors, Events } from "@com.mgmtp.a12/bap-form-engine/lib/back-end/store";
import { ConnectorLocator } from "@com.mgmtp.a12/server-connector/lib/connector/ConnectorLocator";
import { Middleware } from "redux";
import axios from "axios";
import { LoggerFactory } from "@com.mgmtp.a12/logging/lib/factory";
import { isValid, getOfferId } from "../../../utils";

export const acceptOfferWithInvoiceButtonMiddleware: Middleware<{}, EngineState> = api => next => action => {
	if (FormEngineActions.event.match(action)
		// restrict to eventButton, because eventButtonTriggered also has the same event name "accept-offer-with-invoice", but is
		// triggered before validation.
		&& Events.eventButton.match(action.payload.engineEvent)
	) {
		const activity = ActivitySelectors.activityById(action.payload.activityId)(api.getState());
		const event: string = action.payload.engineEvent.payload && action.payload.engineEvent.payload.name;
		const engineState = FormEngineSelectors.engineState(action.payload.activityId)(api.getState());

		const documentModel = engineState && ModelSelectors.documentModel()(engineState);

		if (activity && event && engineState && documentModel) {
			if (event === "accept-offer-with-invoice") {
				const dataHolder = ActivitySelectors.dataHolderByDescriptor(activity.id, activity.descriptor)(api.getState());

				if (dataHolder) {
					const data = dataHolder.data as Activity.Data.SingleDocumentData;

					if (isValid(activity.id, api)) {
						const serverConnector = ConnectorLocator.getInstance().getServerConnector();
						const baseUrl = serverConnector.getBaseUrl ? serverConnector.getBaseUrl() : "";

						const logger = LoggerFactory.getLogger("Invoice");

						api.dispatch(ActivityActions.setData({
							activityId: activity.id,
							data: {
								...data,
								requestPending: true
							}
						}));

						axios({
							method: "post",
							url: baseUrl + "/policy",
							data: { OfferId: getOfferId(data.document) },
							headers: {
								post: {
									"Content-Type": "application/json"
								}
							}
						})
						.then(() => {
							api.dispatch(ActivityActions.setData({
								activityId: activity.id,
								data: {
									...data,
									requestPending: false
								}
							}));

							api.dispatch(FormEngineActions.command({
								engineEvent: Commands.changeScreen({screenName: "PolicyCreated"}),
								activityId: activity.id
							}));
						})
						.catch(error => {
							logger.error(error);
						});
					}
				}
			}
		}
	}
	return next(action);
};
