import * as React from "react";

import { View } from "@com.mgmtp.a12/bap-client/lib/core/view";
import { connect } from "react-redux";
import { TemplateVertical } from "../../templates/TemplateVertical";
import { LocaleSelectors, Localizer } from "@com.mgmtp.a12/bap-client/lib/core/locale";
import { Container } from "@com.mgmtp.a12/bap-client/lib/core/configuration";
import { Locale } from "@com.mgmtp.a12/localization/lib/main";
import { Panel } from "../../ui-components/CollapsibelPanel";
import { Icon } from "@com.mgmtp.a12/widgets";
import { ActivityActions } from "@com.mgmtp.a12/bap-client/lib/core/activity";

type OwnProps = View;

interface DispatchProps {
	openContractOverview(): void;
	openService(): void;
}

interface StateProps {
	locale: Locale;
}

export class CustomerPortalViewComponent extends React.Component<StateProps & OwnProps & DispatchProps> {
	render() {
		const localizer: Localizer = Container.config.get(Container.identifier.Localizer);
		return (
			<TemplateVertical
				id="page--customerPortal"
				localizer={localizer}
				activity={this.props.activity}
				layout={{
					contentPrimarySize: { sm: 4, md: 4, lg: 8 }
				}}
				title={localizer("customerportal.home.title")}
				subtitle={localizer("customerportal.home.subtitle")}
				contentPrimaryContentbox={true}
				contentPrimary={
					<>
						<div className="h_addMarginBottom">
							{localizer("customerportal.home.intro")}
						</div>
						<Panel
							title={localizer("customerportal.home.contractSection.title")}
							content={
								<div className="iconPanelContent">
									<Icon className="icon--white icon--xxl">home</Icon>
									<div>
										<ul className="list--checklist">
											{localizer("customerportal.home.contractSection.list")}
										</ul>
										<p>
											<a onClick={this.props.openContractOverview} id="link--contractOverview">
												{localizer("customerportal.home.contractSection.linkText")}
											</a>
										</p>
									</div>
								</div>
							}
							open={true}
						/>
						<Panel
							title={localizer("customerportal.home.serviceSection.title")}
							content={
								<div className="iconPanelContent">
									<Icon className="icon--white icon--xxl">question_answer</Icon>
									<div>
										<ul className="list--checklist">
											{localizer("customerportal.home.serviceSection.list")}
										</ul>
										<p>
											<a onClick={this.props.openService}>
												{localizer("customerportal.home.serviceSection.linkText")}
											</a>
										</p>
									</div>
								</div>
							}
							open={true}
						/>
					</>
				}
			/>
		);
	}
}

export const CustomerPortalContainer = connect<StateProps, DispatchProps, OwnProps>(
	function mapStateToProps(state) {
		const locale = LocaleSelectors.locale()(state);
		return {
			locale
		};
	},
	function mapDispatchToProps(dispatch, { activity }) {
		return {
			openContractOverview() {
				dispatch(ActivityActions.create({
					activityDescriptor: {
						model: "ContractOverview",
						instance: "__NEW__"
					}
				}));
			},
			openService() {
				dispatch(ActivityActions.create({
					activityDescriptor: {
						model: "Service",
						instance: "__NEW__"
					}
				}));
			}
		};
	}
)(CustomerPortalViewComponent);
