import * as appmodel from "./customer-portal.appmodel.json";
import { Module } from "@com.mgmtp.a12/bap-client/lib/core/application";
import { ApplicationModel } from "@com.mgmtp.a12/bap-client/lib/core/model";
import * as React from "react";
import { View } from "@com.mgmtp.a12/bap-client/lib/core/view";
import { CustomerPortalContainer } from "./CustomerPortalContainer";
import { ContractOverviewContainer } from "./contract-overview/ContractOverviewContainer";
import { ServiceContainer } from "./service/ServiceContainer";
import {
	sendCancellationMiddlewareWrapper,
	sendContactFormMiddlewareWrapper
} from "ggw-customer-portal-common/lib/customer-portal";

function viewComponentProvider(componentName: string): React.ComponentType<View> | undefined {
	return Views[componentName];
}

const Views: { [name: string]: React.ComponentType<View> } = {
	CustomerPortalContainer,
	ServiceContainer,
	ContractOverviewContainer
};

const CustomerPortalModule: Module = {
	id: "customer-portal.module",
	model: () => appmodel as ApplicationModel,
	views: () => viewComponentProvider,
	middlewares: () => [
		sendCancellationMiddlewareWrapper("/policy/fy-customer-portal/cancel-withdraw"),
		sendContactFormMiddlewareWrapper("/policy/fy-customer-portal/contact")
	]
};

export default CustomerPortalModule;
