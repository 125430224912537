import * as React from "react";
import { contextPath } from "../utils";

const faqSection: JSX.Element = <>
		<p>
			Do you have a question concerning your insurance?
		</p>
		<p>
			No problem – please consult our list of <a href={`${contextPath}/documents/en/Frequently_asked_questions_Photovoltaic_insurance.pdf`} target="_blank">Frequently Asked Questions.</a>
		</p>
</>;

const footerLinkList: JSX.Element = <>
	<li>
		<a href={`${contextPath}/documents/en/Conditions_of_Use_GGW.pdf`} target="_blank">
			Terms of Use
		</a>
	</li>
	<li>
		<a href={`${contextPath}/documents/en/GTC_GGW.pdf`} target="_blank">
			GTC
		</a>
	</li>
	<li>
		<a href={`${contextPath}/documents/en/Mandatory_Information_GGW.pdf`} target="_blank">
			Mandatory Information
		</a>
	</li>
	<li>
		<a href={`${contextPath}/documents/en/Privacy_Policy_GGW.pdf`} target="_blank">
			Privacy Policy
		</a>
	</li>
</>;

const footerContent: JSX.Element = <>
	<p>© 2020 Gossler, Gobert & Wolters Gruppe</p>
	<h3>About GGW</h3>
	<p>
		Gossler, Gobert & Wolters is one of the largest independent and owner-managed insurance brokers in Germany.
		We advise companies in all matters relating to security, risk and supply management at a total of ten locations.
		In cooperation with our corporate customers, we also develop insurance solutions for their products, which
		buyers can exclusively co-purchase.
	</p>
	<p>
		GGW offers high-quality insurance products with exceptional benefits, which are continuously developed and
		negotiated with various insurers. We benefit from our many years of experience and broad industry expertise.&nbsp;
		<a href="https://www.ggw.de/english" target="_blank">MORE ABOUT GGW</a>
	</p>
</>;

const premiumCalculationErrorMessage: JSX.Element = <>
	<p>
		For technical reasons, the premium calculation is not available at present.
	</p><p>
		Please try again later.
	</p><p>
		Thank you for your understanding.
	</p>
</>;

const offerRequestServerErrorMessage: JSX.Element = <>
	<p>
		For technical reasons, the offer request is not available at present.
	</p><p>
		Please try again later.
	</p><p>
		Thank you for your understanding.
	</p>
</>;

const offerRequestMultiRegistrationErrorMessage: JSX.Element = <>
	<p>
		The e-mail you entered has already been registered.
	</p><p>
		Is this your account? Then please log in here before proceeding.
	</p><p>
		Thank you for your understanding.
	</p>
</>;

const pageOfferExpiredContent: JSX.Element = <>
	<p>Unfortunately, this offer has expired.</p>
	<p>Please contact us to receive a current offer.</p>
	<p>Thank you for your understanding.</p>
</>;

const offerSentContent: JSX.Element = <>
	<p>Thank you for your interest in PV Insurance! An email with all relevant information is on its way to you.</p>
	<p>As soon as you want to sign the contract with us, please click on the link in the email and follow the steps.</p>
</>;

const pageAlreadyPayedContent: JSX.Element = <>
	<p>The premium for this contract has already been paid. Please check your emails.</p>
	<p>Thank you very much for your trust.</p>
</>;

const passwordRules = `
<p>Your password should fulfill the following conditions:</p>
<ul>
	<li>have at least 8 characters</li>
	<li>contain at least one upper case letter</li>
	<li>contain at least one special character</li>
	<li>contain at least one lower case letter</li>
	<li>contain at least one number</li>
	<li>have at most 30 characters</li>
</ul>`;

const customerPortalIntro: JSX.Element = <>
	<p>
		Dear customer,<br />
		with just a few clicks and without waiting time, you can view your contract
		documents here anytime and from anywhere.
	</p>
</>;

const contractPanelList: JSX.Element = <>
	<li>Download & view all contracts and invoices</li>
	<li>Revocation & cancellation of a contract</li>
</>;

const servicePanelList: JSX.Element = <>
	<li>Frequently asked questions</li>
	<li>Contact form</li>
</>;

const mailError: JSX.Element = <>
	<p>
		For technical reasons, we're unable to send your message right now.
	</p><p>
		Please try again later.
	</p><p>
		Thank you very much for your understanding.
	</p>
</>;

export const resources: {} = {
	"application.title": "",
	"contact.title": "Contact",
	"contract.content.title": "Request your non-binding offer for yacht insurance here simple and convenient",
	"contract.subtitle": "Photovoltaic insurance",
	"contract.title": "All risks covered in one insurance policy",
	"cookieConsent.content": "We use cookies in order to operate our websites. " +
		"Currently, we only store essential cookies that are necessary for the basic functions of the website. " +
		"They are needed for safe and proper use and therefore cannot be deactivated. " +
		"We use Six Saferpay for payment processing. " +
		"By clicking the button \"OK\" you agree to the use.",
	"cookieConsent.acceptButton": "OK",
	"customerportal.contractOverview.label.policyNumber": "Policy number",
	"customerportal.contractOverview.label.inceptionDate": "Commencement of insurance",
	"customerportal.contractOverview.title": "Customer portal - My contracts",
	"customerportal.contractOverview.subtitle": "All your contracts at a glance",
	"customerportal.home.contractSection.linkText": "to my contracts",
	"customerportal.home.contractSection.list": contractPanelList,
	"customerportal.home.contractSection.title": "My contracts",
	"customerportal.home.serviceSection.linkText": "to the service center",
	"customerportal.home.serviceSection.list": servicePanelList,
	"customerportal.home.serviceSection.title": "Service Center",
	"customerportal.home.intro": customerPortalIntro,
	"customerportal.home.subtitle": "Welcome to your customer portal!",
	"customerportal.home.title": "Customer portal - Your homepage",
	"customerportal.mail.error": mailError,
	"customerportal.mail.success": "Your email has been sent.",
	"customerportal.service.title": "Customer portal – Service center",
	"customerportal.service.subtitle": "Use our extensive online services.",
	"customerportal.service.faqSection.content": faqSection,
	"customerportal.service.faqSection.title": "Frequently asked questions",
	"pageOfferExpired.title": "We are sorry. The offer has expired.",
	"pageOfferExpired.content": pageOfferExpiredContent,
	"pageNotFound.title": "This page could not be found!",
	"pageAlreadyPayed.title": "Payment already received",
	"pageAlreadyPayed.content": pageAlreadyPayedContent,
	"footer.content": footerContent,
	"footer.link.imprint": "Legal notice",
	"footer.linkList": footerLinkList,
	"footer.productInfo": "An exclusive product for BayWa r.e. customers",
	"header.logo.link": "https://www.ggw.de/english",
	"login.button": "Login",
	"login.forgotPassword": "Forgot your password?",
	"login.password": "Password",
	"login.title": "Customer Login",
	"login.user": "Username",
	"offerSent.title": "Your offer was sent to you by email!",
	"offerSent.content": offerSentContent,
	"password.rules.content": passwordRules,
	"payment.aborted": "The payment process was cancelled.",
	"payment.failed": "The payment has failed. Please try again.",
	"payment.errormessage": "Payment is currently not possible.",
	"premium.calculation.error": premiumCalculationErrorMessage,
	"offer.request.serverError": offerRequestServerErrorMessage,
	"offer.request.multiRegistrationError": offerRequestMultiRegistrationErrorMessage,
	"offer.request.multiRegistrationError.login": "Login",
	// translations for LoginPage component
	"auth.form.title": "PV Insurance - Login",
	"auth.form.username.label": "Username (e-mail)",
	"auth.form.password.reset.label": "Forgot your password?",
	"locale.de_DE": "Deutsch",
	"locale.en_US": "English"
};
