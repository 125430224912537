import { Route } from "./types";
import { Activity } from "@com.mgmtp.a12/bap-client/lib/core/activity";
// @ts-ignore
import _ from "lodash";
import { routes, pageNotFoundRoute } from "./routes";
import { User } from "@com.mgmtp.a12/user-api/lib/User";
import { contextPath } from "../utils";

export const offerRoutePattern = /^offer\/(.*?)\//;

/**
 * Removes trailing slash from url
 */
export function sanitizeRoute(route: string): string {
	return removeTrailingSlash(route);
}

/**
 * Removes trailing slash from url
 */
export function removeTrailingSlash(route: string): string {
	return route.endsWith("/") ? route.slice(0, -1) : route;
}

export function removeLeadingSlash(route: string): string {
	return route.startsWith("/") ? route.slice(1) : route;
}

/**
 * Checks if descriptor of activity and route are matching.
 * Additionally, if a screenName is passed, it will be checked if it matches the screenName of the route.
 */
export function isMatching(route: Route, activity: Activity, screenName?: string): boolean {
	if (screenName) {
		return route.screenName === screenName && _.isEqual(route.descriptor.model, activity.descriptor.model);
	}
	return _.isEqual({ ...activity.descriptor, ...route.descriptor }, activity.descriptor);
}

function getLocationFullRoute() {
	return location.href.replace(location.origin + contextPath + "/", "");
}

/**
 * Returns offerId from offer route
 */
export function getOfferIdFromOfferRoute(routeName: string) {
	const documentIdResult = routeName.match(offerRoutePattern);
	return documentIdResult ? documentIdResult[1] : null;
}

export function isBaseUrl(): boolean {
	return removeTrailingSlash(location.origin + contextPath) === removeTrailingSlash(location.href);
}

export function hasUserRequiredRole(route: Route, user: User): boolean {
	if (route.requiredRole) {
		return user.roles.find(role => role.ident.name === route.requiredRole) != undefined;
	}
	return true;
}

export function findCurrentRoute(): Route {
	const fullRoute = getLocationFullRoute();
	return routes.find(route => route.match(fullRoute)) || pageNotFoundRoute;
}
