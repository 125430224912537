import * as React from "react";

import { TemplateVertical } from "../../../templates/TemplateVertical";
import { FormContainer } from "./FormContainer";
import { Model } from "@com.mgmtp.a12/bap-client/lib/core/model";
import { View } from "@com.mgmtp.a12/bap-client/lib/core/view";
import { Localizer, LocaleSelectors } from "@com.mgmtp.a12/bap-client/lib/core/locale";
import { Container } from "@com.mgmtp.a12/bap-client/lib/core/configuration";
import { connect } from "react-redux";
import { Locale } from "@com.mgmtp.a12/localization/lib/main";

type OwnProps = View;

interface StateProps {
	formModel?: Model;
	currentScreenName?: string;
	locale: Locale;
}

export class OfferCreationComponent extends React.Component<StateProps & OwnProps> {
	private screenIcons = {
		BoatData: "directions_boat",
		PremiumData: "create",
		PolicyHolder: "person",
		Summary: "insert_drive_file",
		Payment: "add_shopping_cart",
		PolicyCreated: "check_circle"
	};

	private invisibleScreens = [ "PaymentPending" ];

	private scrollRef = React.createRef<HTMLDivElement>();

	scrollToTop = () => {
		if (this.scrollRef && this.scrollRef.current) {
			this.scrollRef.current.scrollIntoView();
		}
	}

	render() {
		const localizer: Localizer = Container.config.get(Container.identifier.Localizer);
		return (
			<>
				<TemplateVertical
					localizer={localizer}
					activity={this.props.activity}
					layout={{
						contentPrimarySize: { sm: 4, md: 6, lg: 12 }
					}}
					title={localizer("contract.title")}
					contentPrimaryContentbox={false}
					contentPrimaryTitle={localizer("contract.content.title")}
					contentPrimary={
						<FormContainer
							{...this.props}
							screenIcons={this.screenIcons}
							scrollToTop={this.scrollToTop}
							invisibleScreens={this.invisibleScreens}
						></FormContainer>
					}
				>
					<div className="scrollElement" ref={this.scrollRef}/>
				</TemplateVertical>
			</>
		);
	}
}

export const OfferCreationContainer = connect<StateProps, {}>(
	function mapStateToProps(state) {
		const locale = LocaleSelectors.locale()(state);

		return {
			locale
		};
	}
)(OfferCreationComponent);
