import { FormModel } from "@com.mgmtp.a12/bap-form-engine/lib/models";

/**
 * Returns annotation of ModelElement by given name.
 *
 * @param modelElement - Model Element with annotations
 * @param name - Name of annotation
 * @return - The annotation
 */
// tslint:disable-next-line:no-any
export function getAnnotation(modelElement: any, name: string): FormModel.Annotation {
	const annotations = modelElement.annotation;
	return annotations && annotations.find((d: FormModel.Annotation) => d.name === name);
}
