import { ApplicationModel } from "@com.mgmtp.a12/bap-client/lib/core/model";
import { call } from "redux-saga/effects";
import { Activity } from "@com.mgmtp.a12/bap-client/lib/core/activity";
import MatchCondition = ApplicationModel.MatchCondition;
import { offerScreenActions } from "ggw-customer-portal-common/lib/offer-creation";
import { CUSTOMER_ROLE, MODEL_NAME } from "../config/config";

export const activityInitializers: { matchConditions: MatchCondition[], initializer(activity: Activity): IterableIterator<{}> }[] = [
	{
		matchConditions: [
			{
				key: "model",
				mustEqual: MODEL_NAME
			},
			{
				key: "instance",
				mustEqual: "__NEW__"
			}
		],
		* initializer(activity: Activity) {
			yield call(offerScreenActions, activity, CUSTOMER_ROLE);
		}
	}
];
