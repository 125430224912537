import { FormEngineActions } from "@com.mgmtp.a12/bap-client/lib/extensions/bap-form-engine";
import { EngineState } from "@com.mgmtp.a12/bap-form-engine/lib/back-end/store";

import { ActivitySelectors, ActivityActions, ActivityMap } from "@com.mgmtp.a12/bap-client/lib/core/activity";
import { Middleware } from "redux";

import { MODEL_NAME } from "../../../config/config";

export const openContractPageMiddleware: Middleware<{}, EngineState> = api => next => action => {
	if (FormEngineActions.event.match(action)) {
		const activity = ActivitySelectors.activityById(action.payload.activityId)(api.getState());
		const event: string = action.payload.engineEvent.payload && action.payload.engineEvent.payload.name;

		if (activity && event) {
			if (event === "event_open-contract-page") {
				// jump into ContractModule in nav menu
				const topLevelActivities = ActivityMap.toList(ActivitySelectors.topLevelActivities()(api.getState()));
				api.dispatch(ActivityActions.cancelRequested({
					activityIds: topLevelActivities.map(topLevelActivity => topLevelActivity.id)
				}));

				api.dispatch(ActivityActions.create({
					activityDescriptor: {
						instance: "__NEW__",
						model: MODEL_NAME
					}
				}));
			}
		}
	}
	return next(action);
};
