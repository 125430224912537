/**
 * Application configuration for development.
 *
 * Configures configuration and services.
 */
import { Resources as formEngineResources } from "@com.mgmtp.a12/bap-form-engine/lib/back-end/localization";
import { Container } from "@com.mgmtp.a12/bap-client/lib/core/configuration";
import { LocaleFactories, LocaleResources } from "@com.mgmtp.a12/bap-client/lib/core/locale";
import { InspectorResources } from "@com.mgmtp.a12/bap-client/lib/extensions/inspector";
import { Locale } from "@com.mgmtp.a12/localization/lib/main/Locale";
import { DefaultValueLocalizerService, ResourceLocalizerService } from "@com.mgmtp.a12/localization/lib/main/services";
import { ConsoleLoggingStrategy } from "@com.mgmtp.a12/logging";
import { LogLevel } from "@com.mgmtp.a12/logging/api";

import resources from "./resources";

Container.config.bind(Container.identifier.LoggingStrategy).toConstantValue(
	new ConsoleLoggingStrategy(console, LogLevel.LOG)
);

export const locales = ["de_DE", "en_US"];

Container.config.bind(Container.identifier.Locales).toConstantValue(
	locales.map(locale => Locale.fromString(locale))
);

const localeProvider = LocaleFactories.createLocaleProvider(Locale.fromString("de_DE"));
Container.config.bind(Container.identifier.LocaleProvider).toConstantValue(localeProvider);

const resourceLocalizerService = LocaleFactories.createLocalizedResourceBundleLocalizerService(
	InspectorResources,
	resources,
	LocaleResources
);
const chainedLocalizerService = LocaleFactories.createChainLocalizerService(
	resourceLocalizerService,
	new ResourceLocalizerService(formEngineResources),
	new DefaultValueLocalizerService()
);

Container.config.bind(Container.identifier.LocalizerService).toConstantValue(chainedLocalizerService);

const localizer = LocaleFactories.createLocalizer(chainedLocalizerService, localeProvider);
Container.config.bind(Container.identifier.Localizer).toConstantValue(localizer);
