import * as React from "react";

import { FormModelMap, DefaultFormModelMap } from "@com.mgmtp.a12/bap-form-engine/lib/view";
import { FormModel } from "@com.mgmtp.a12/bap-form-engine/lib/models";
import { getAnnotation } from "./utils";
import { Activity } from "@com.mgmtp.a12/bap-client/lib/core/activity";
import { Localizer } from "@com.mgmtp.a12/bap-client/lib/core/locale";
import { Container } from "@com.mgmtp.a12/bap-client/lib/core/configuration";
import { LayoutGrid, MessageBox, Icon, ProgressIndicator } from "@com.mgmtp.a12/widgets";
import { getDataHolder, hasUrlParameter } from "../../../utils";
import { CustomActions } from "ggw-customer-portal-common/lib/force-login";
import { useDispatch } from "react-redux";
import { SaferpayComponent } from "ggw-customer-portal-common/lib/saferpay";

const { Row, Column } = LayoutGrid;

export namespace CustomComponents {
	// Return Payment Section if section in UI Model has annotation "payment"
	export const SectionComponent = (
		(
			props: FormModelMap.FormModelComponentProps<FormModel.Section>,
			activity: Activity,
			offerRequestSuccessful: boolean,
			offerRequestSent: boolean,
			offerRequestMultiRegistration: boolean,
			paymentFailed: boolean,
			manualOfferCreation: boolean
		): JSX.Element | null => {
			const paymentAnnotation = getAnnotation(props.modelElement, "payment");
			const offerErrorSectionAnnotation = getAnnotation(props.modelElement, "offer-error-section");
			const paymentErrorSectionAnnotation = getAnnotation(props.modelElement, "payment-error-section");
			const paymentPendingAnnotation = getAnnotation(props.modelElement, "payment-pending");
			const manualOfferInfoSection = getAnnotation(props.modelElement, "manual-offer-info-section");

			if (paymentAnnotation) {
				const dataHolder = getDataHolder(activity);

				// tslint:disable-next-line:no-any
				const payment = dataHolder && (dataHolder.data as any).payment;
				const redirectUrl = payment && payment.redirectUrl;

				if (!payment || !payment.redirectUrl) {
					return null;
				}

				return <SaferpayComponent
					activity={activity}
					// if redirectUrl is undefined, MessageBox with error will be shown
					redirectUrl={redirectUrl}
				/>;
			}

			if (offerErrorSectionAnnotation) {
				if (!offerRequestSent) {
					return null;

				} else if (!offerRequestSuccessful) {
					if (offerRequestMultiRegistration) {
						const localizer: Localizer = Container.config.get(Container.identifier.Localizer);
						const dispatch = useDispatch();
						return (<LayoutGrid>
							<Row>
								<Column size={{ sm: 4, md: 4, lg: 8 }}>
									<MessageBox variant="error" label={localizer("offer.request.multiRegistrationError")} icon={<Icon>error</Icon>}
												action={<a
														onClick={() => dispatch(CustomActions.setForceLogin(true))}>{localizer("offer.request.multiRegistrationError.login")}</a>}/>
								</Column>
							</Row>
						</LayoutGrid>);
					} else {
						return createMessageBox("offer.request.serverError", "error");
					}
				}
			}

			const urlParameterName = getAnnotation(props.modelElement, "url-parameter-name");
			if (urlParameterName && urlParameterName.value) {
				const messageKey = getAnnotation(props.modelElement, "message-key").value;
				if (hasUrlParameter(urlParameterName.value) && messageKey) {
					return createMessageBox(messageKey, "error");
				} else {
					return null;
				}
			}

			if (paymentErrorSectionAnnotation) {
				if (!paymentFailed) {
					return null;
				} else {
					return createMessageBox("payment.failed", "error");
				}
			}

			if (paymentPendingAnnotation) {
				return (
					<div className="paymentPending__progressIndicator">
						<ProgressIndicator/>
					</div>
				);
			}

			if (manualOfferInfoSection) {
				if (manualOfferCreation) {
					return createMessageBox("offer.manualCreation", "success");
				}
			}

			return (<DefaultFormModelMap.Section.component {...props} />);
		});

	// expression cells with premium annotations can be rendered to look like labels
	export const ExpressionCellComponent =
		(props: FormModelMap.FormModelComponentProps<FormModel.ExpressionCell>)
			: JSX.Element | null => {
			const premiumAnnotation = getAnnotation(props.modelElement, "premium-style");

			return (
				!premiumAnnotation
					? <DefaultFormModelMap.ExpressionCell.component {...props} />
					: <div className={`expressionCell expressionCell--${premiumAnnotation.value}`}>
						<DefaultFormModelMap.ExpressionCell.component {...props} />
					</div>
			);
		};

	// only render premium section after calculation was done and showPremiumCalculations was set
	export const MultiColumnSectionComponent =
		(
			props: FormModelMap.FormModelComponentProps<FormModel.MultiColumnSection>,
			showPremiumCalculations: boolean,
			premiumCalculationSuccessful: boolean,
			paymentWithInvoice: boolean
		)
			: JSX.Element | null => {

			// Show premium section only if premium has already been calculated
			const isPremiumSection = props.modelElement.name === "Premium_Section";
			if (isPremiumSection) {
				if (!showPremiumCalculations) {
					return null;
				} else if (!premiumCalculationSuccessful) {
					return createMessageBox("premium.calculation.error", "error");
				}
			}

			// Show Invoice section only when paying with invoice
			const isInvoiceSection = props.modelElement.name === "Invoice_Section";
			if (isInvoiceSection && !paymentWithInvoice) {
				return null;
			}

			// Show Payment section only when not paying with invoice
			const isPaymentSection = props.modelElement.name === "Payment_Section";
			if (isPaymentSection && paymentWithInvoice) {
				return null;
			}

			return <DefaultFormModelMap.MultiColumnSection.component {...props} />;
		};
}

type MessageBoxType = "error" | "success" | "warning";
function createMessageBox(localizationKey: string, type: MessageBoxType) {
	const localizer: Localizer = Container.config.get(Container.identifier.Localizer);
	return (<LayoutGrid>
		<Row>
			<Column size={{ sm: 4, md: 4, lg: 8 }}>
				{
					type !== "success"
						? <MessageBox variant={type} label={localizer(localizationKey)} icon={<Icon>error</Icon>}/>
						: <div className="messageBox h_messageBox--success">
							{localizer(localizationKey)}
						</div>
				}
			</Column>
		</Row>
	</LayoutGrid>);
}
