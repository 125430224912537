import { FormEngineActions } from "@com.mgmtp.a12/bap-client/lib/extensions/bap-form-engine";
import { EngineState, Events } from "@com.mgmtp.a12/bap-form-engine/lib/back-end/store";

import { ActivitySelectors } from "@com.mgmtp.a12/bap-client/lib/core/activity";
import { Middleware } from "redux";
import { CustomActions } from "ggw-customer-portal-common/lib/force-login";

export const loginButtonMiddleware: Middleware<{}, EngineState> = api => next => action => {
	if (FormEngineActions.event.match(action)
		&& Events.eventButtonTriggered.match(action.payload.engineEvent)
	) {
		const activity = ActivitySelectors.activityById(action.payload.activityId)(api.getState());
		const event: string = action.payload.engineEvent.payload && action.payload.engineEvent.payload.name;

		if (activity && event) {
			if (event === "event_redirect-to-login") {
				api.dispatch(CustomActions.setForceLogin(true));
			}
		}
	}
	return next(action);
};
