import { Module } from "@com.mgmtp.a12/bap-client/lib/core/application";
import CustomerPortalModule from "./customer-portal/customer-portal-module";
import OfferCreationModule from "./offer-creation/offer-creation-module";

const PRODUCT_PAGE_MODULES: Module[] = [
	OfferCreationModule,
	CustomerPortalModule
];

export default PRODUCT_PAGE_MODULES;
