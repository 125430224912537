import { FormEngineSelectors } from "@com.mgmtp.a12/bap-client/lib/extensions/bap-form-engine";
import { MiddlewareAPI } from "redux";
import { Activity } from "@com.mgmtp.a12/bap-client/lib/core/activity";
import _ from "lodash";

export function isValid(activityId: string, api: MiddlewareAPI) {
	const engineState = FormEngineSelectors.engineState(activityId)(api.getState());
	if (engineState !== undefined) {
		return Object.keys(engineState.ui.messages).map(key =>
			engineState.ui.messages[key]!.parseError === undefined
			&& engineState.ui.messages[key]!.validationMessages.filter(m => m.severity === "ERROR").length === 0
		).every(entry => entry === true);
	}
	return false;
}

export function getDataHolder(activity: Activity): Activity.DataHolder | undefined {
	const { dataHolders = [] } = activity;
	const foundDataHolder = dataHolders.find(Activity.DataHolder.hasDescriptor(activity.descriptor));
	return foundDataHolder;
}

/**
 * Read context path from metadata in head of index.html
 */
function getContextPath(): string {
	const metaData: any = document.head.querySelector("[name='context.path'][content]");
	if (metaData) {
		return metaData.content || "";
	}
	return "";
}

export const contextPath = getContextPath();

// see https://davidwalsh.name/query-string-javascript
export function getUrlParameter(name: string): string {
	const regex = new RegExp("[\\?&]" + _.escapeRegExp(name) + "=([^&#]*)", "g");
	const results = regex.exec(location.search);
	return results === null ? "" : decodeURIComponent(results[1].replace(new RegExp("\\+", "g"), " "));
}

export function hasUrlParameter(name: string): boolean {
	const regex = new RegExp("[\\?&]" + _.escapeRegExp(name) + "=([^&#]*)", "g");
	return regex.test(location.search);
}

export function getOfferId(document: Activity.Data.Document): string {
	return document.Root.CosmoOfferId;
}
