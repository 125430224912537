import { Commands, EngineState, Events } from "@com.mgmtp.a12/bap-form-engine/lib/back-end/store";
import { Middleware } from "redux";
import { FormEngineActions } from "@com.mgmtp.a12/bap-client/lib/extensions/bap-form-engine";
import { ActivitySelectors } from "@com.mgmtp.a12/bap-client/lib/core/activity";

export const backButtonMiddleware: Middleware<{}, EngineState> = api => next => action => {
	if (FormEngineActions.event.match(action) && Events.navigationButton.match(action.payload.engineEvent)) {
		const activity = ActivitySelectors.activityById(action.payload.activityId)(api.getState());
		const event: string = action.payload.engineEvent.payload && action.payload.engineEvent.payload.target;

		if (activity && event) {
			if (event === "#previous") {
				/* We want to delete any validation messages if a user uses a navigation button to go back to a previous screen.
				Already stored error messages from previous validations (that got not resolved before navigating back) would otherwise lead to dead ends due to validation errors.
				While navigating back, we don't wanna do any validation. Whenever we move forward again, we also validate again anyway. */
				api.dispatch(FormEngineActions.command( {
					engineEvent: Commands.setMessageState( {
						messages: {} // delete messages by setting to empty
					}),
					activityId: activity.id
				}));
			}
		}
	}
	return next(action);
};
