import * as React from "react";

import { InputMap } from "@com.mgmtp.a12/bap-form-engine/lib/view";
import { DocumentModel } from "@com.mgmtp.a12/kernel-facade";
import { TextOutput } from "@com.mgmtp.a12/widgets";
import { DefaultInputModelMap } from "@com.mgmtp.a12/bap-form-engine/lib/view/internal/configuration/Defaults";
import { getAnnotation } from "./utils";

export namespace CustomInputComponents {
	// number field with left aligned values and fields in premium section as TextOutputs
	export const NumberInputComponent =
		(props: InputMap.InputProps<DocumentModel.NumberDataType>): React.ReactElement | null => {
			const premiumStyleAnnotation = getAnnotation(props.modelElement, "premium-style");
			const suffixAnnotation = getAnnotation(props.modelElement, "suffix");
			if (premiumStyleAnnotation) {
				let labelValue: string | undefined = premiumStyleAnnotation.value === "totalPremiumValue"
					? undefined
					: props.modelElement.label;
				if (labelValue) {
					labelValue = labelValue.endsWith("*") ? labelValue.substring(0, labelValue.length - 1) : labelValue;
				}
				return (
					<TextOutput
						className={`premium__field premium__field--${premiumStyleAnnotation.value}`}
						label={labelValue}
					>
						{props.value.ui + ` ${suffixAnnotation ? suffixAnnotation.value : ""}`}
					</TextOutput>
				);
			}
			return (
				<>
					<div className="h_leftAlign h_flex" >
						<DefaultInputModelMap.NumberInput.component {...props} />
					</div>
				</>
			);
		};
}
