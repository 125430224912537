import * as React from "react";

import { View } from "@com.mgmtp.a12/bap-client/lib/core/view";
import { TemplateVertical } from "../../../templates/TemplateVertical";
import { Localizer } from "@com.mgmtp.a12/bap-client/lib/core/locale";
import { Container } from "@com.mgmtp.a12/bap-client/lib/core/configuration";

type OwnProps = View;

export class OfferSentContainer extends React.Component<OwnProps> {
	render() {
		const localizer: Localizer = Container.config.get(Container.identifier.Localizer);
		return (
			<TemplateVertical
				localizer={localizer}
				activity={this.props.activity}
				layout={{
					contentPrimarySize: { sm: 4, md: 4, lg: 8 }
				}}
				title={localizer("offerSent.title")}
				contentPrimaryContentbox={false}
				contentPrimary={
					<div className="h_addMarginTop">
						<div className="messageBox h_messageBox--success">
							{localizer("offerSent.content")}
						</div>
					</div>
				}
			/>
		);
	}
}
