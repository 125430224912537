import { Route } from "./types";
import { createSaferpayPage, triggerPaymentExecutionSaferpay } from "./sagas";
import { Activity } from "@com.mgmtp.a12/bap-client/lib/core/activity";
import { call } from "redux-saga/effects";
import { CUSTOMER_ROLE, MODEL_NAME } from "../config/config";

export const routes: Route[] = [
	{
		route: "offer",
		descriptor: {
			model: MODEL_NAME,
			instance: "__NEW__"
		},
		match: (fullRoute => fullRoute === "offer")
	},
	{
		route: "offer/payment",
		descriptor: {
			model: MODEL_NAME
		},
		screenName: "Payment",
		* screenActions(activity: Activity) {
			yield call(createSaferpayPage, activity);
		},
		requiredRole: CUSTOMER_ROLE,
		match: (fullRoute => /^offer(\/.*)?\/payment/.test(fullRoute))

	},
	{
		route: "offer/payment-pending",
		descriptor: {
			model: MODEL_NAME
		},
		* screenActions(activity: Activity) {
			yield call(triggerPaymentExecutionSaferpay, activity);
		},
		screenName: "PaymentPending",
		requiredRole: CUSTOMER_ROLE,
		match: (fullRoute => /^offer(\/.*)?\/payment-pending/.test(fullRoute))
	},
	{
		route: "offer/payment-successful",
		descriptor: {
			model: MODEL_NAME
		},
		screenName: "PolicyCreated",
		requiredRole: CUSTOMER_ROLE,
		match: (fullRoute => /^offer(\/.*)?\/payment-successful/.test(fullRoute))
	},
	{
		route: "customerportal",
		descriptor: {
			container: "CustomerPortalContainer"
		},
		requiredRole: CUSTOMER_ROLE,
		match: (fullRoute => fullRoute === "customerportal")
	},
	{
		route: "customerportal/contract-overview",
		descriptor: {
			model: "ContractOverview",
			instance: "__NEW__"
		},
		requiredRole: CUSTOMER_ROLE,
		match: (fullRoute => fullRoute === "customerportal/contract-overview")
	},
	{
		route: "customerportal/service",
		descriptor: {
			model: "Service",
			instance: "__NEW__"
		},
		requiredRole: CUSTOMER_ROLE,
		match: (fullRoute => fullRoute === "customerportal/service")
	},
	{
		route: "payment-pending",
		descriptor: {
			container: "PaymentPendingContainer"
		},
		* screenActions(activity: Activity) {
			yield call(triggerPaymentExecutionSaferpay, activity);
		},
		match: (fullRoute => fullRoute === "payment-pending")
	},
	{
		route: "payment-successful",
		descriptor: {
			container: "PaymentDoneContainer"
		},
		match: (fullRoute => fullRoute === "payment-successful")
	}
];

export const pageOfferExpiredRoute: Route = {
	route: "page-offer-expired",
	descriptor: {
		container: "PageOfferExpiredContainer"
	},
	match: (fullRoute => fullRoute === "page-offer-expiredt")
};

export const pageNotFoundRoute: Route = {
	route: "page-not-found",
	descriptor: {
		container: "PageNotFoundContainer"
	},
	match: (fullRoute => true)
};

export const offerNotFoundRoute: Route = {
	route: "offer-not-found",
	descriptor: {
		container: "OfferNotFoundContainer"
	},
	match: (fullRoute => true)
};

export const pageAlreadyPayedRoute: Route = {
	route: "page-already-payed",
	descriptor: {
		container: "PageAlreadyPayedContainer"
	},
	match: (fullRoute => fullRoute === "page-already-payed")
};

// @ts-ignore
export const offerRouteName = "offer";
