import * as React from "react";

const premiumCalculationErrorMessage: JSX.Element = <>
	<p>
		Aus technischen Gründen steht die Prämienberechnung zurzeit nicht zur Verfügung.
	</p><p>
		Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.
	</p><p>
		Vielen Dank für Ihr Verständnis.
	</p>
</>;

const offerRequestServerErrorMessage: JSX.Element = <>
	<p>
		Aus technischen Gründen steht die Angebotsanforderung zurzeit nicht zur Verfügung.
	</p><p>
		Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.
	</p><p>
		Vielen Dank für Ihr Verständnis.
	</p>
</>;

const offerRequestMultiRegistrationErrorMessage: JSX.Element = <>
	<p>
		Die von Ihnen eingegebene E-Mail wurde bereits registriert.
	</p><p>
		Handelt es sich hierbei um Ihr Benutzerkonto? Dann loggen Sie sich bitte ein, bevor Sie fortfahren.
	</p><p>
		Vielen Dank für Ihr Verständnis.
	</p>
</>;

const pageOfferExpiredContent: JSX.Element = <>
	<p>Dieses Angebot ist leider abgelaufen.</p>
	<p>Bitte melden Sie sich bei uns um ein aktuelles Angebot zu erhalten.</p>
	<p>Vielen Dank für Ihr Verständnis.</p>
</>;

const offerSentContent: JSX.Element = <>
	<p>Vielen Dank für Ihr Interesse an unserer Firmenich Yachtversicherung! Eine E-Mail mit allen relevanten Informationen ist auf dem Weg zu Ihnen.</p>
	<p>Sobald Sie den Vertrag mit uns abschließen wollen, klicken Sie bitte auf den Link in der E-Mail und folgen Sie den restlichen Schritten.</p>
</>;

const pageAlreadyPayedContent: JSX.Element = <>
	<p>Die Prämie für diesen Vertrag wurde bereits gezahlt. Bitte prüfen Sie Ihre Emails.</p>
	<p>Vielen Dank für Ihr Vertrauen.</p>
</>;

const policyNotCreatedContent: JSX.Element = <>
	<h1>Fehler beim Erstellen Ihrer Police</h1>
	<div>
		Die Bezahlung war erfolgreich, jedoch trat beim Erstellen Ihrer Police ein Problem auf.
		Bitte setzen Sie sich mit dem Support in Verbindung oder warten Sie, bis wir Sie kontaktieren.
	</div>
</>;

const offerNotFoundContent: JSX.Element = <>
	<h1>Angebot nicht gefunden</h1>
	<div>Es konnte kein Angebot für diese ID gefunden werden. Bitte überprüfen Sie den Link.</div>
</>;

const passwordRules = `
<p>Ihr Passwort sollte die folgenden Bedingungen erfüllen:</p>
<ul>
	<li>aus mindestens aus 8 Zeichen bestehen</li>
	<li>mindestens ein Großbuchstaben enthalten</li>
	<li>mindestens ein Sonderzeichen enthalten</li>
	<li>mindestens einen Kleinbuchstaben enthalten</li>
	<li>mindestens eine Zahl enthalten</li>
	<li>höchstens aus 30 Zeichen bestehen</li>
</ul>`;

const customerPortalIntro: JSX.Element = <>
	<p>
		Lieber Kunde, <br />
		mit wenigen Klicks und ohne Wartezeit können Sie hier jederzeit und von überall
		Ihre Vertragsunterlagen einsehen.
	</p>
</>;

const contractPanelList: JSX.Element = <>
	<li>Download & Einsicht aller Verträge und Rechnungen</li>
	<li>Widerruf & Stornierung eines Vertrages</li>
</>;

const servicePanelList: JSX.Element = <>
	<li>Häufig gestellte Fragen</li>
	<li>Kontaktformular</li>
</>;

const mailError: JSX.Element = <>
	<p>
		Aus technischen Gründen ist das Versenden der Nachricht im Moment nicht möglich.
	</p><p>
		Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.
	</p><p>
		Vielen Dank für Ihr Verständnis.
	</p>
</>;

const offerManualCreation: JSX.Element = <>
	<p>Anhand der angegebenen Daten ist es uns derzeit leider nicht möglich, Ihnen ein vollautomatisiertes Angebot zukommen zu lassen.</p>
	<p>Geben Sie bitte im nächsten Schritt die Informationen zum Versicherungsnehmer ein, damit wir uns mit Ihnen in Verbindung setzen können.</p>
</>;

export const resources: {} = {
	"application.title": "",
	"contact.title": "Kontakt",
	"contract.content.title": "Fordern Sie hier einfach & bequem Ihr unverbindliches Angebot zur Yacht-Versicherung an",
	"contract.title": "Alle Risiken in einer Versicherung abgedeckt",
	"cookieConsent.content": "Um unsere Webseiten betreiben zu können, verwenden wir Cookies. " +
		"Aktuell speichern wir lediglich wesentliche Cookies die für die Grundfunktionen der Website erforderlich sind. " +
		"Sie dienen der sicheren und bestimmungsgemäßen Nutzung und sind daher nicht deaktivierbar. " +
		"Zur Zahlungsabwicklung verwenden wir Six Saferpay. " +
		"Durch Bestätigen des Buttons \"OK\" stimmen Sie der Verwendung zu.",
	"cookieConsent.acceptButton": "OK",
	"customerportal.contractOverview.label.policyNumber": "Vertragsnummer",
	"customerportal.contractOverview.label.inceptionDate": "Versicherungsbeginn",
	"customerportal.contractOverview.title": "Kundenportal - Meine Verträge",
	"customerportal.contractOverview.subtitle": "Immer aktuell & übersichtlich",
	"customerportal.home.contractSection.linkText": "zu meinen Verträgen",
	"customerportal.home.contractSection.list": contractPanelList,
	"customerportal.home.contractSection.title": "Meine Verträge",
	"customerportal.home.serviceSection.linkText": "zum Service-Center",
	"customerportal.home.serviceSection.list": servicePanelList,
	"customerportal.home.serviceSection.title": "Service-Center",
	"customerportal.home.intro": customerPortalIntro,
	"customerportal.home.subtitle": "Herzlich willkommen in Ihrem Kundenportal!",
	"customerportal.home.title": "Kundenportal - Ihre Startseite",
	"customerportal.mail.error": mailError,
	"customerportal.mail.success": "Ihre Mail wurde versendet.",
	"customerportal.service.title": "Kundenportal - Service-Center",
	"customerportal.service.subtitle": "Profitieren Sie von unseren umfangreichen Online-Services.",
	"customerportal.title": "Kundenportal",
	"offerNotFound.content": offerNotFoundContent,
	"pageOfferExpired.title": "Es tut uns leid. Die Angebotsgültigkeit ist leider abgelaufen.",
	"pageOfferExpired.content": pageOfferExpiredContent,
	"pageNotFound.title": "Diese Seite konnte nicht gefunden werden!",
	"pageAlreadyPayed.title": "Zahlung bereits erhalten",
	"pageAlreadyPayed.content": pageAlreadyPayedContent,
	"policyNotCreated.content": policyNotCreatedContent,
	"login.button": "Anmelden",
	"login.forgotPassword": "Passwort vergessen?",
	"login.password": "Passwort",
	"login.title": "Kundenlogin",
	"login.user": "Benutzername",
	"offerSent.title": "Ihr Angebot wurde Ihnen per Email zugesandt!",
	"offerSent.content": offerSentContent,
	"password.rules.content": passwordRules,
	"payment.aborted": "Der Zahlungsprozess wurde abgebrochen.",
	"payment.failed": "Die Zahlung ist fehlgeschlagen. Bitte versuchen Sie es noch einmal.",
	"payment.errormessage": "Die Bezahlung ist zur Zeit nicht möglich.",
	"premium.calculation.error": premiumCalculationErrorMessage,
	"offer.manualCreation": offerManualCreation,
	"offer.request.serverError": offerRequestServerErrorMessage,
	"offer.request.multiRegistrationError": offerRequestMultiRegistrationErrorMessage,
	"offer.request.multiRegistrationError.login": "Login",
	// translations for LoginPage component
	"auth.form.title": "Firmenich Yachtversicherung - Login",
	"auth.form.submit.label": "Login",
	"auth.form.username.label": "Benutzername (E-Mail)",
	"auth.form.password.reset.label": "Passwort vergessen?",
	"locale.de_DE": "Deutsch",
	"locale.en_US": "English"
};
