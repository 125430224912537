import * as React from "react";
import { connect } from "react-redux";
import { FormEngineActions } from "@com.mgmtp.a12/bap-client/lib/extensions/bap-form-engine";
import { Events } from "@com.mgmtp.a12/bap-form-engine/lib/back-end/store";
import { Activity } from "@com.mgmtp.a12/bap-client/lib/core/activity";
import _ from "lodash";

interface OwnProps {
	activity: Activity;
}

interface DispatchProps {
	openContractPage(): void;
}

class FyHeaderComponent extends React.Component<DispatchProps & OwnProps> {
	getClassNames() {
		return _.isEqual(this.props.activity.descriptor, { instance: "__NEW__", model: "FyCustomerPortal" })
			? "forward trail current" : "";
	}

	render(): JSX.Element {
		return (
			<>
				<div id="header-wrapper">
					<div id="headerbild"></div>
					<div id="header" className="container">
						<div className="row">
							<div className="4u">
								<h1>
									<a target="_blank" href="https://firmenich-yacht.de/">FIRMENICH</a>
								</h1>
							</div>
							<div className="8u">
								<nav className="xxx mod_navigation block" id="nav">
									<ul className="level_1" role="menubar">
										<li className="submenu first">
											<a href="https://firmenich-yacht.de/versicherungen.html" target="_blank" title="Versicherungen"
											   className="submenu first" role="menuitem" aria-haspopup="true">Versicherungen</a>
											<ul className="level_2" role="menu">
												<li className="submenu first">
													<a href="https://firmenich-yacht.de/versicherungen/fuer-eigner/yacht-kasko.html"
													   target="_blank" title="Für Eigner" className="submenu first" role="menuitem"
													   aria-haspopup="true">Für Eigner</a>
													<ul className="level_3" role="menu">
														<li className="first"><a
															href="https://firmenich-yacht.de/versicherungen/fuer-eigner/yacht-kasko.html"
															target="_blank" title="Yacht-Kasko" className="first" role="menuitem">Yacht-Kasko</a>
														</li>
														<li><a
															href="https://firmenich-yacht.de/versicherungen/fuer-eigner/yacht-haftpflicht.html"
															target="_blank" title="Yacht-Haftpflicht" role="menuitem">Yacht-Haftpflicht</a></li>
														<li><a
															href="https://firmenich-yacht.de/versicherungen/fuer-eigner/yacht-insassenunfall.html"
															target="_blank" title="Yacht-Insassenunfall" role="menuitem">Yacht-Insassenunfall</a>
														</li>
														<li className="last"><a
															href="https://firmenich-yacht.de/versicherungen/fuer-eigner/yacht-rechtsschutz.html"
															target="_blank" title="Yacht-Rechtsschutz" className="last"
															role="menuitem">Yacht-Rechtsschutz</a></li>
													</ul>
												</li>
												<li className="submenu">
													<a href="https://firmenich-yacht.de/versicherungen/fuer-charterer/skipper-haftpflicht.html"
													   target="_blank" title="Für Charterer" className="submenu" role="menuitem"
													   aria-haspopup="true">Für Charterer</a>
													<ul className="level_3" role="menu">
														<li className="first last"><a
															href="https://firmenich-yacht.de/versicherungen/fuer-charterer/skipper-haftpflicht.html"
															target="_blank" title="Skipper-Haftpflicht" className="first last"
															role="menuitem">Skipper-Haftpflicht</a></li>
													</ul>
												</li>
												<li className="last"><a
													href="https://firmenich-yacht.de/versicherungen/bootsversicherung.html"
													target="_blank" title="Bootsversicherung" className="last"
													role="menuitem">Bootsversicherung</a></li>
											</ul>
										</li>
										<li className="submenu">
											<a href="https://firmenich-yacht.de/schadenservice.html" target="_blank" title="Schadenservice"
											   className="submenu" role="menuitem" aria-haspopup="true">Schadenservice</a>
											<ul className="level_2" role="menu">
												<li className="submenu first">
													<a href="https://firmenich-yacht.de/schadenservice/yacht-kasko-schaden/tipps-und-anweisungen.html"
													   target="_blank" title="Yacht-Kasko-Schaden" className="submenu first" role="menuitem"
													   aria-haspopup="true">Yacht-Kasko-Schaden</a>
													<ul className="level_3" role="menu">
														<li className="first"><a
															href="https://firmenich-yacht.de/schadenservice/yacht-kasko-schaden/tipps-und-anweisungen.html"
															target="_blank" title="Tipps und Anweisungen" className="first" role="menuitem">Tipps
															und Anweisungen</a></li>
														<li className="last"><a
															href="https://firmenich-yacht.de/schadenservice/yacht-kasko-schaden/schadenformular.html"
															target="_blank" title="Schadenformular" className="last"
															role="menuitem">Schadenformular</a></li>
													</ul>
												</li>
												<li className="submenu last">
													<a href="https://firmenich-yacht.de/schadenservice/yacht-haftpflicht-schaden/tipps-und-anweisungen.html"
													   target="_blank" title="Yacht-Haftpflicht-Schaden" className="submenu last" role="menuitem"
													   aria-haspopup="true">Yacht-Haftpflicht-Schaden</a>
													<ul className="level_3" role="menu">
														<li className="first"><a
															href="https://firmenich-yacht.de/schadenservice/yacht-haftpflicht-schaden/tipps-und-anweisungen.html"
															target="_blank" title="Tipps und Anweisungen" className="first" role="menuitem">Tipps
															und Anweisungen</a></li>
														<li className="last"><a
															href="https://firmenich-yacht.de/schadenservice/yacht-haftpflicht-schaden/schadenformular.html"
															target="_blank" title="Schadenformular" className="last"
															role="menuitem">Schadenformular</a></li>
													</ul>
												</li>
											</ul>
										</li>
										<li className={`submenu ${this.getClassNames()}`}>
											<a
												title="Angebot"
												className="forward trail submenu"
												role="menuitem"
												aria-haspopup="true"
												onClick={this.props.openContractPage}
											>
												Angebot
											</a>
										</li>
										<li className="submenu">
											<a href="https://firmenich-yacht.de/service.html" target="_blank" title="Service" className="submenu"
											   role="menuitem" aria-haspopup="true">Service</a>
											<ul className="level_2" role="menu">
												<li className="submenu first">
													<a href="https://firmenich-yacht.de/service/kundenservice/policenaenderung.html"
													   target="_blank" title="Kundenservice" className="submenu first" role="menuitem"
													   aria-haspopup="true">Kundenservice</a>
													<ul className="level_3" role="menu">
														<li className="first"><a
															href="https://firmenich-yacht.de/service/kundenservice/policenaenderung.html"
															target="_blank" title="Policenänderung" className="first"
															role="menuitem">Policenänderung</a></li>
														<li className="last"><a
															target="_blank" href="https://firmenich-yacht.de/service/kundenservice/downloads.html"
															title="Downloads" className="last" role="menuitem">Downloads</a></li>
													</ul>
												</li>
												<li className="submenu">
													<a href="https://firmenich-yacht.de/service/gebrauchtbootkauf/checkliste-boot.html"
													   target="_blank" title="Gebrauchtbootkauf" className="submenu" role="menuitem"
													   aria-haspopup="true">Gebrauchtbootkauf</a>
													<ul className="level_3" role="menu">
														<li className="first"><a
															href="https://firmenich-yacht.de/service/gebrauchtbootkauf/checkliste-boot.html"
															target="_blank" title="Checkliste Boot" className="first" role="menuitem">Checkliste
															Boot</a></li>
														<li className="last"><a
															href="https://firmenich-yacht.de/service/gebrauchtbootkauf/muster-kaufvertrag-boot.html"
															target="_blank" title="Muster-Kaufvertrag Boot" className="last" role="menuitem">Muster-Kaufvertrag
															Boot</a></li>
													</ul>
												</li>
												<li className="submenu">
													<a href="https://firmenich-yacht.de/service/firmenich-informiert/boots-haftpflicht.html"
													   target="_blank" title="FIRMENICH informiert" className="submenu" role="menuitem"
													   aria-haspopup="true">FIRMENICH informiert</a>
													<ul className="level_3" role="menu">
														<li className="first"><a
															href="https://firmenich-yacht.de/service/firmenich-informiert/boots-haftpflicht.html"
															target="_blank" title="Boots-Haftpflicht" className="first"
															role="menuitem">Boots-Haftpflicht</a></li>
														<li><a
															href="https://firmenich-yacht.de/service/firmenich-informiert/boots-kasko.html"
															target="_blank" title="Boots-Kasko" role="menuitem">Boots-Kasko</a></li>
														<li><a
															href="https://firmenich-yacht.de/service/firmenich-informiert/skipper-haftpflicht.html"
															target="_blank" title="Skipper-Haftpflicht" role="menuitem">Skipper-Haftpflicht</a>
														</li>
														<li className="last"><a
															href="https://firmenich-yacht.de/service/firmenich-informiert/mindestdeckungssummen.html"
															target="_blank" title="Mindestdeckungssummen" className="last"
															role="menuitem">Mindestdeckungssummen</a></li>
													</ul>
												</li>
												<li className="submenu last">
													<a href="https://firmenich-yacht.de/service/sonstiges/links.html"
													   target="_blank" title="Sonstiges" className="submenu last" role="menuitem"
													   aria-haspopup="true">Sonstiges</a>
													<ul className="level_3" role="menu">
														<li className="first last"><a
															href="https://firmenich-yacht.de/service/sonstiges/links.html"
															target="_blank" title="Links" className="first last" role="menuitem">Links</a></li>
													</ul>
												</li>
											</ul>
										</li>
										<li><a href="https://firmenich-yacht.de/download.html" target="_blank" title="Downloads"
											   role="menuitem">Downloads</a></li>
										<li className="submenu">
											<a href="https://firmenich-yacht.de/ueber-uns.html" target="_blank" title="Über uns"
											   className="submenu" role="menuitem" aria-haspopup="true">Über uns</a>
											<ul className="level_2" role="menu">
												<li className="first"><a href="https://firmenich-yacht.de/ueber-uns/historie.html"
																		 target="_blank" title="Historie" className="first"
																		 role="menuitem">Historie</a></li>
												<li><a href="https://firmenich-yacht.de/ueber-uns/das-team.html" target="_blank" title="Das Team"
													   role="menuitem">Das Team</a></li>
												<li><a href="https://firmenich-yacht.de/ueber-uns/karriere.html" target="_blank" title="Karriere"
													   role="menuitem">Karriere</a></li>
												<li className="last"><a
													href="https://firmenich-yacht.de/ueber-uns/ggw-gruppe.html" target="_blank" title="GGW Gruppe"
													className="last" role="menuitem">GGW Gruppe</a></li>
											</ul>
										</li>
										<li className="submenu last">
											<a href="https://firmenich-yacht.de/kontakt/adresse-und-anfahrt.html" target="_blank" title="Kontakt"
											   className="submenu last" role="menuitem" aria-haspopup="true">Kontakt</a>
											<ul className="level_2" role="menu">
												<li className="first"><a
													href="https://firmenich-yacht.de/kontakt/adresse-und-anfahrt.html"
													target="_blank" title="Adresse und Anfahrt" className="first" role="menuitem">Adresse und
													Anfahrt</a></li>
												<li className="last"><a href="https://firmenich-yacht.de/kontakt/presse.html"target="_blank"
																		title="Presse" className="last" role="menuitem">Presse</a>
												</li>
											</ul>
										</li>
									</ul>
									<a id="skipNavigation1" className="invisible">&nbsp;</a>
								</nav>
							</div>
						</div>
					</div>
					<div id="zwischenheader">
					</div>
				</div>
			</>);
	}
}

export const FyHeader = connect<{}, DispatchProps, OwnProps>(
	undefined,
	function mapDispatchToProps(dispatch, { activity }) {
		return {
			openContractPage() {
				dispatch(FormEngineActions.event({
					engineEvent: {
						type: Events.eventButtonTriggered,
						payload: {
							name: "event_open-contract-page"
						}
					},
					activityId: activity.id
				}));
			}
		};
	}
)(FyHeaderComponent);
