// These imports must stay at the top because they define globals
import "./config/dev.config";
import "./static/static-page-imports";

import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider, connect } from "react-redux";

import { FrameFactories, FrameViews } from "@com.mgmtp.a12/bap-client/lib/core/frame";

import { setup } from "./appsetup";
import { AuthenticationSelectors } from "@com.mgmtp.a12/bap-client/lib/core/authentication";

import * as cell from "@com.mgmtp.a12/bap-form-engine/lib/view/internal/components/form-engine/cells/cell";
import { LayoutGrid } from "@com.mgmtp.a12/widgets";

import { hasUserRequiredRole, findCurrentRoute } from "./routing/utils";
import { ViewViews } from "@com.mgmtp.a12/bap-client/lib/core/view";
import { ApplicationSelectors } from "@com.mgmtp.a12/bap-client/lib/core/application";
import { ActivitySelectors } from "@com.mgmtp.a12/bap-client/lib/core/activity";
import { User } from "@com.mgmtp.a12/user-api/lib/User";
import { LocaleSelectors } from "@com.mgmtp.a12/bap-client/lib/core/locale";
import { Locale } from "@com.mgmtp.a12/localization/lib/main";
import { CustomSelectors } from "ggw-customer-portal-common/lib/force-login";
import { createProgressComponentProvider } from "ggw-customer-portal-common/lib/progress-component";
import { contextPath } from "./utils";
import ReactGA from "react-ga";
import { trackRoute, getPathnameForGoogleAnalytics, getTrackingId } from "./google-analytics/utils";

/*
	override the get function of SizeCOntainerLookup.
	THIS IS A HACK!
	it is necessary because it's not possible to set layouts for multiple devices in the UI designer
	and the layout breaks on tablet/mobile devides
	Could be removed with: A12-11420
*/
// tslint:disable-next-line:no-any
(cell as any).SizeContainerSizeLookup.get = (lg: LayoutGrid.LGColumnSize) => {
	/*
	// original lookupTable, please leave here as reference
	const lookupTable: SizeContainerSizeLookup = [
		{ lg: 1, md: 1, sm: 1 },
		{ lg: 2, md: 3, sm: 2 },
		{ lg: 3, md: 3, sm: 2 },
		{ lg: 4, md: 2, sm: 2 },
		{ lg: 5, md: 3, sm: 4 },
		{ lg: 6, md: 3, sm: 4 },
		{ lg: 7, md: 3, sm: 4 },
		{ lg: 8, md: 4, sm: 4 },
		{ lg: 9, md: 4, sm: 4 },
		{ lg: 10, md: 6, sm: 4 },
		{ lg: 11, md: 6, sm: 4 },
		{ lg: 12, md: 6, sm: 4 }
	];
	*/
	const lookupTable = [
		{ lg: 1, md: 1, sm: 1 },
		{ lg: 2, md: 3, sm: 1 },
		{ lg: 3, md: 3, sm: 1 },
		{ lg: 4, md: 2, sm: 1 },
		{ lg: 5, md: 3, sm: 2 },
		{ lg: 6, md: 3, sm: 2 },
		{ lg: 7, md: 3, sm: 3 },
		{ lg: 8, md: 4, sm: 3 },
		{ lg: 9, md: 4, sm: 4 },
		{ lg: 10, md: 6, sm: 4 },
		{ lg: 11, md: 6, sm: 4 },
		{ lg: 12, md: 6, sm: 4 }
	];
	return lookupTable[lg - 1];
};

const delay = 3000;
const { config, viewComponentProvider, initialStoreActions } = setup(delay);

interface StateProps {
	readonly busy: boolean;
	readonly authenticated: string;
	readonly user: User | undefined;
	readonly locale: Locale;
	readonly forceLogin: boolean;
	readonly location: string;
}

const progressComponentProvider = createProgressComponentProvider();

class PageTemplate extends React.Component<StateProps> {
	componentDidMount() {
		trackRoute();
	}

	componentDidUpdate(prevProps: StateProps, prevState: any) {
		if (this.props.location !== prevProps.location) {
			trackRoute();
		}
	}

	render() {
		const rootRegionRef: string[] = [];
		const RegionUi = FrameFactories.regionUiProvider(rootRegionRef);
		return isAuthenticatedForCurrentRoute(this.props.authenticated, this.props.user) && !this.props.forceLogin
			? <>
				<RegionUi
					regionReference={rootRegionRef}
					layoutProvider={customLayoutProvider}
					regionUiProvider={FrameFactories.regionUiProvider}
					viewComponentProvider={viewComponentProvider}
					progressComponentProvider={progressComponentProvider}
				/>
			</>
			: <ViewViews.ProgressIndicator progress={this.props.busy ? "loading" : "none"}>
				<FrameViews.LoginPage imageURL={`${contextPath}/images/firmenich-background.jpg`}
									  logoURL={`${contextPath}/images/fy-logo-login.png`} enablePasswordReset={true}/>
			</ViewViews.ProgressIndicator>;
	}
}

const Page = connect<StateProps>(
	function mapStateToProps(state) {
		return {
			forceLogin: CustomSelectors.forceLogin()(state),
			locale: LocaleSelectors.locale()(state),
			authenticated: AuthenticationSelectors.state()(state),
			busy: ApplicationSelectors.busy()(state),
			activities: ActivitySelectors.activities()(state),
			user: AuthenticationSelectors.state()(state) === "AUTHENTICATED" ? AuthenticationSelectors.user()(state) : undefined,
			location: getPathnameForGoogleAnalytics(location.pathname)
		};
	}
)(PageTemplate);

const customLayoutProvider: FrameViews.LayoutProvider = name => {
	return name === "ApplicationFrame"
		? CustomApplicationFrameLayoutConnected
		: FrameFactories.layoutProvider(name);
};

function CustomApplicationFrameLayout(props: FrameViews.ApplicationFrameLayoutProps & HeaderProps): JSX.Element {
	return (
		<FrameViews.ApplicationFrameLayout
			{...props}
			mainMenuComponent={() => <></>}
		/>
	);
}

interface HeaderProps {
	locale: Locale;
}

const CustomApplicationFrameLayoutConnected = connect<HeaderProps, {}, {}>(
	function mapStateToProps(state) {
		return {
			locale: LocaleSelectors.locale()(state)
		};
	}
)(CustomApplicationFrameLayout);

const mountpoint = document.createElement("div");
// mountpoint.classList.add("page-wrapper");
document.body.appendChild(mountpoint);

initialStoreActions().then(() => {
	config.store.subscribe(() => {
		ReactGA.initialize(getTrackingId());
		ReactDOM.render(
			<Provider store={config.store}>
				<Page/>
			</Provider>,
			mountpoint
		);
	});
});

function isAuthenticatedForCurrentRoute(authenticated: string, user: User | undefined) {
	const currentRoute = findCurrentRoute();
	return authenticated === "AUTHENTICATED" && user != undefined && hasUserRequiredRole(currentRoute, user);
}
