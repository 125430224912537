import * as React from "react";

import { TemplateVertical } from "../../../templates/TemplateVertical";
import { ContractOverviewFormContainer } from "ggw-customer-portal-common/lib/customer-portal";
import { Model } from "@com.mgmtp.a12/bap-client/lib/core/model";
import { View } from "@com.mgmtp.a12/bap-client/lib/core/view";
import { Localizer, LocaleSelectors } from "@com.mgmtp.a12/bap-client/lib/core/locale";
import { Container } from "@com.mgmtp.a12/bap-client/lib/core/configuration";
import { connect } from "react-redux";
import { Locale } from "@com.mgmtp.a12/localization/lib/main";

type OwnProps = View;

interface StateProps {
	formModel?: Model;
	locale: Locale;
}

export class ContractOverviewComponent extends React.Component<StateProps & OwnProps> {
	render() {
		const localizer: Localizer = Container.config.get(Container.identifier.Localizer);
		return (
			<TemplateVertical
				id="page--contractOverview"
				localizer={localizer}
				activity={this.props.activity}
				layout={{
					contentPrimarySize: { sm: 4, md: 6, lg: 12 }
				}}
				title={localizer("customerportal.contractOverview.title")}
				subtitle={localizer("customerportal.contractOverview.subtitle")}
				contentPrimaryContentbox={false}
				contentPrimaryTitle={localizer("customerportal.contractOverview.content.title")}
				contentPrimary={
					<ContractOverviewFormContainer
						{...this.props}
					></ContractOverviewFormContainer>
				}
			/>
		);
	}
}

export const ContractOverviewContainer = connect<StateProps, {}>(
	function mapStateToProps(state) {
		const locale = LocaleSelectors.locale()(state);

		return {
			locale
		};
	}
)(ContractOverviewComponent);
