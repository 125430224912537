import { EngineState } from "@com.mgmtp.a12/bap-form-engine/lib/back-end/store";
import { ActivitySelectors, ActivityActions, Activity } from "@com.mgmtp.a12/bap-client/lib/core/activity";
import { Middleware } from "redux";
import { SaferpayActions } from "ggw-customer-portal-common/lib/saferpay";
import { AuthenticationSelectors } from "@com.mgmtp.a12/bap-client/lib/core/authentication";

/**
 * set policyholder data on payment page in case user was already registered
 */
export const policyHolderOnPaymentPageMiddleware: Middleware<{}, EngineState> = api => next => action => {
	if (SaferpayActions.saferpayPageRequested.match(action)) {
		const activity = ActivitySelectors.activityById(action.payload.activityId)(api.getState());

		if (activity) {
			const dataHolder = ActivitySelectors.dataHolderByDescriptor(activity.id, activity.descriptor)(api.getState());
			const data = dataHolder?.data as Activity.Data.SingleDocumentData;
			const policyHolderData = data.document.Root.PolicyHolder;

			if (policyHolderData.AlreadyRegistered) {
				const user = AuthenticationSelectors.user()(api.getState());
				const userData = { FirstName: user.firstName, LastName: user.lastName };
				if (policyHolderData.PolicyHolderType === "natural") {
					data.document.Root.PolicyHolder.PolicyHolderNatural.PolicyHolderName = userData;
				} else {
					data.document.Root.PolicyHolder.PolicyHolderLegal.ContactPerson = userData;
				}
				api.dispatch(ActivityActions.setData({
					activityId: activity.id,
					data: {
						...data
					}
				}));
			}
		}
	}
	return next(action);
};
