import { SagaIterator } from "redux-saga";
import { call } from "redux-saga/effects";

import * as DeepLinkingSagas from "./sagas";

export namespace DeepLinkingFactories {
	/**
	 * Provides the application sagas that are necessary for the deep linking functionality
	 */
	export function createSagas(): (() => SagaIterator)[] {
		return [
			function* (): SagaIterator {
				yield call(DeepLinkingSagas.watchLastActivatedActivityChanged);
			},
			function* (): SagaIterator {
				yield call(DeepLinkingSagas.watchLoadingApplication);
			},
			function* (): SagaIterator {
				yield call(DeepLinkingSagas.watchScreenChanged);
			}
		];
	}
}
